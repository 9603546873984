import {
  Card,
  Grid,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation, useRouteMatch } from "react-router";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./DashboardUnit.style";
import BacnetDeviceStats from "../Bacnet/BacnetDeviceStats";


const DashboardBacnetDevice: React.FC<RouteComponentProps<any> & any> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const match = useRouteMatch<{ unitId: string, sensorId: string, siteId: string, powerId: string, bacnetDeviceId: string }>();
  let { siteId = "", bacnetDeviceId = "" } = match.params;
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [refetchBacnetDevice, setRefetchBacnetDevice] = useState<boolean>(false);
  const [bacnetDevice, setBacnetDevice] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<any>({}); //time picker on graph
  const [isLoading, setIsLoading] = useState(true);
  const [bacnetDevicesLoaded, setBacnetDevicesLoaded] = useState<boolean>(false);

  const { customer: customerId = "", device: deviceId = "" }: any = bacnetDevice || {};

  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const allBacnetDevices = useStoreState((state) => state.bacnetDevices.allBacnetDevices);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const bacnetDeviceCustomer = allCustomers[customerId] || null;
  const { bacnetDeviceId: selectedBacnetDeviceId, siteId: selectedSiteId, customerId: selectedCustomerId } = useStoreState((s) => s.selections.selections);
  const bacnetDeviceSite = useStoreState((s) => s.sites.getSite(selectedSiteId));
  const bacnetDeviceDevice = allDevices[deviceId];
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const {
    fetchSiteBacnetDevices,
    fetchCustomerBacnetDevices,
  } = useStoreActions((action) => action.bacnetDevices);

  useEffect(() => {
    if (!selectedCustomerId) {
        return;
    }
    if (!selectedSiteId) {
        fetchCustomerBacnetDevices(selectedCustomerId)
        .then((res: any) => setBacnetDevicesLoaded(true))
        .catch((err: any) => addMessage({ message: err.message }));
    }else{
        fetchSiteBacnetDevices(selectedSiteId)
        .then((res: any) => setBacnetDevicesLoaded(true))
        .catch((err: any) => addMessage({ message: err.message }));
    }
  }, [selectedSiteId,selectedCustomerId]);

  useEffect(() => {
    if (_.isEmpty(selectedTime)) {
      return;
    }

    const timeLimit = sitesFlags[selectedSiteId || ""]?.numOfHourLimitUnitDiag || 0;
    if (timeLimit === 0) {
      return;
    }
    const { selectedStartTime, selectedEndTime } = selectedTime;
    const timeNow = new Date().getTime();
    const pastTimeAllowed = timeNow - (timeLimit * 60 * 60 * 1000);
    if (selectedStartTime < pastTimeAllowed || selectedEndTime < pastTimeAllowed) {
      setSelectedTime({ selectedStartTime: pastTimeAllowed, selectedEndTime: timeNow });
    }
  }, [selectedSiteId]);

  useEffect(() => {
    if (!bacnetDevicesLoaded) {
      return
    }
    const type = selectedBacnetDeviceId ? "bacnetDevice" : "";

    if (!type || (type !== "bacnetDevice" && !selectedSiteId)) {
      onBacnetDeviceSelect(null);
      return;
    }
    if (type === "bacnetDevice" && selectedSiteId && selectedCustomerId) {
      onBacnetDeviceSelect(selectedBacnetDeviceId);
      return;
    }
  }, [selectedBacnetDeviceId,bacnetDevicesLoaded]);

  useEffect(() => {
    if (!selectedBacnetDeviceId) {
      setBacnetDevice(null);
    }else{
      setBacnetDevice(allBacnetDevices[selectedBacnetDeviceId])
    }

  },[selectedBacnetDeviceId])


  useEffect(() => {
    if (!refetchBacnetDevice) {
      return;
    }
    setRefetchBacnetDevice(false);
    setBacnetDevice(allBacnetDevices[bacnetDeviceId]);
  }, [refetchBacnetDevice]);

  const onBacnetDeviceSelect = (bacnetDeviceId: string | null) => {
    const { bacnetDeviceId: urlId } = match.params

    if (bacnetDeviceId) {
      history.push({
        pathname: `/bacnet-device-stats/${bacnetDeviceId}`
      });
    }
    else 
    if (urlId) {
      updateSelections({ type: 'bacnetDevice', data: urlId });
    }
    else {
      history.push("/bacnet-device-stats/");
    }
  };

  const clearBacnetDevice = (bacnetDeviceId: string | null) => {
    history.push(`/bacnet-device-stats/`);
  };


  const deviceRegDate = (bacnetDeviceDevice?.registrationDate) || new Date("1-1-1900");

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.rightSide}>
        <Header
          customGeneralNames={{ unit: t`Select Unit` }}
          hideSystemSelection
          hideUnitSelection
          showBacnetDevices
          onSiteSelect={clearBacnetDevice}
          onCustomerSelect={clearBacnetDevice}
          applySiteTypeFiltering
          screenTitle="bacnetDeviceDiag"
          allowUnassignedControls
        />

        {bacnetDevice &&
          !_.isNil(bacnetDeviceCustomer) &&
          !_.isNil(bacnetDeviceSite) ? (
          <Grid container direction={"row"} className={classes.contentContainer}>
            {/* --- start of paramsContainer  */}
            <Grid container direction={"row"} className={classes.halfPadding}>
              <Grid item xs={12} className={classes.paramsContainer}>
                <Card className={classes.statsRapper}>
                  <BacnetDeviceStats
                      site={bacnetDeviceSite}
                      unit={bacnetDevice}
                      setSelectedTime={setSelectedTime}
                      setReFetchUnit={setRefetchBacnetDevice}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedTime={selectedTime}
                      minDate={deviceRegDate}
                    />
                </Card>
              </Grid>
              {/* --- end of paramsContainer */}
            </Grid>
          </Grid>
        ) : (
          <FilterRequire type={t`BACnet device`} />
        )}
      </div>
    </div>
  );
};

export default DashboardBacnetDevice;
