import {
    IconButton,
    TableCell,
    TableRow
} from "@material-ui/core";
import { EditIcon } from "../../logos";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import clsx from "clsx";
import { t } from "ttag";
import useStyles from "./BacnetDeviceList.style";
import { IBacnetDevice } from "@models/BacnetDevices";
import React from "react";
import _ from "lodash";

interface IProps {
    bacnetDevice: IBacnetDevice&{serial:string}
    canUpdate: boolean,
    setOpenForm: any
    setCurrentEntity: any
}

const BacnetDeviceTableRow: React.FC<IProps> = (props) => {
    const classes = useStyles();

    const { bacnetDevice, canUpdate, setOpenForm, setCurrentEntity } = props;

    return(
        <TableRow
              hover
              tabIndex={-1}
              key={bacnetDevice.id}
            >
              <TableCell
                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                align="left">
                {bacnetDevice?.serial ?? '<?>'}
              </TableCell>

              <TableCell
                component="th"
                scope="row"
                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                align="left"
              >
                {`${bacnetDevice.network}:${bacnetDevice.bacnetId}`}
              </TableCell>

              <TableCell
                component="th"
                scope="row"
                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                align="left"
              >
                {!_.isEmpty(bacnetDevice.title) ? bacnetDevice.title : bacnetDevice.name}
              </TableCell>
              
              <TableCell
                component="th"
                scope="row"
                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                align="left"
              >
                {bacnetDevice.description}
              </TableCell>
              <TableCell classes={{ root: classes.overWritePadding }} align="left">
                <LightTooltip title={t`Edit device`}>
                  <IconButton
                    disabled={!canUpdate}
                    disableRipple
                    onClick={() => {
                      setOpenForm(true);
                      setCurrentEntity(bacnetDevice);
                    }}
                    className={classes.iconBtnStyle}
                  >
                    <EditIcon />
                  </IconButton>
                </LightTooltip>
              </TableCell>
            </TableRow>            
    );
}

const compareFn = function (prevProps: IProps, nextProps:IProps) {
  const result = prevProps.bacnetDevice.id === nextProps.bacnetDevice.id && prevProps.bacnetDevice.title === nextProps.bacnetDevice.title
  return result
}

export default React.memo(BacnetDeviceTableRow,compareFn)