import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Popover, TextField, Typography } from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { Site as sdkSite, SiteSubscriptions as sdkSiteSubscriptions } from "coolremote-sdk";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import AddEditFloorPlan from "../../components/AddEditFloorPlan/AddEditFloorPlan";
import Header from "../../components/Header/Header";
import { Loader } from "../../components/Loader";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { MoreVert } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { validatorForm } from "../../services/formService";
import { MenuSearch as Search } from "../../svgComponents";
import AddEditSite from "./AddEditSite";
import AddEditSystem from "./AddEditSystem";
import AssignLineUnits from "./AssignLineUnits";
import { CreateOtherUnitPopUp } from "./CustomedModals";
import RegisterDevice from "./Device/RegisterDevice";
import DisconnectedUnitsDialog from "./DisconnectedUnitsDialog";
import EditSubType from "./EditSubType";
import FoldersTableDialog from "./FilesTableDialog";
import ManuallySetPPDs from "./ManuallySetPPDs";
import ManuallySetSensors from "./ManuallySetSensors";
import PowerMetersMappingDialog from "./PowerMetersControl";
import useStyles from "./siteManagement.style";
import SiteSubscriptions from "./SiteSubscriptions/SiteSubscriptions";
import SiteView from "./SiteView";
import SiteViewMgmt from "./SiteViewMgmt";
import SystemSchedule from "./SystemSchedule";
import SystemServiceMapping from "./SystemServiceMaping";
import UnmappedUnitsDialog from "./UnmappedUnitsDialog";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Tooltip from "../../components/Tooltip/LightTooltip";

interface IDevicesRegistrationsInfoRequired { 
  [deviceBrand: string]: Array<{ id: string; label: string; isNotRequired?: boolean; }>; 
}

/** An Enum for DeviceRegistrationLabels */
export const DRL = {
  /** Device Serial Number (SN) */
  DeviceSerialNumber:t`Device Serial Number (SN)`,
  /** Device PIN */
  DevicePIN:t`Device PIN`,
  /**Device Location */
  Location:t`Device Location`,
  /** Device API key */
  APIKey:t`Device API key`,
  /** Device API Secret */
  APISecret:t`Device API Secret`,
}

const devicesRegistrationsInfoRequired: IDevicesRegistrationsInfoRequired = {
  0: [{ id: "serial", label: DRL.DeviceSerialNumber }, { id: "pin", label: DRL.DevicePIN }, { id: "description", label: DRL.Location, isNotRequired: true }],
  1: [{ id: "sensiboApiKey", label: DRL.APIKey }, { id: "description", label: DRL.Location, isNotRequired: true }],
  2: [{ id: "monnitApiKey", label: DRL.APIKey }, { id: "monnitApiSecret", label: DRL.APISecret }, { id: "description", label: DRL.Location, isNotRequired: true }]
};


let formValidatorDeviceInfo: any;

const SiteManagement: React.FC<RouteComponentProps<any> & any> = (props: any) => {
  const classes: any = useStyles();
  const getSiteMgmtData = useStoreActions((a) => a.sites.getSiteMgmtData);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const changeSystemOperationMode = useStoreActions((action) => action.systems.changeSystemOperationMode);
  const systemTogglePower = useStoreActions((action) => action.systems.systemTogglePower);
  const deleteSite = useStoreActions((action) => action.deleteSite);
  const deleteDevice = useStoreActions((action) => action.deleteDevice);
  const approveSiteAPI = useStoreActions((action) => action.sites.approveSite);
  const updateDevice = useStoreActions((action) => action.devices.updateDevice);
  const replaceDevice = useStoreActions((action) => action.devices.replaceDevice);
  const mapLineIndoors = useStoreActions((actions) => actions.devices.mapLineIndoors);
  const createOtherUnit = useStoreActions((action) => action.units.createOtherUnit);
  const fetchAllUnits = useStoreActions((actions) => actions.units.getUnits);
  const addNewDeviceToSite = useStoreActions((action) => action.createDevice);
  const getSystems = useStoreActions((action) => action.systems.getSystems);
  const getAllSystemCustomized = useStoreActions((action) => action.systems.getAllSystemCustomized);
  const updateUserPreferences = useStoreActions((action) => action.users.updateUserPreferences);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const isIdle = useStoreState((state) => state.isIdle);
  const selections = useStoreState((state) => state.selections.selections);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const types = useStoreState((state) => state.types);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const deviceBrands = useStoreState((state) => state.types.deviceBrands);
  const getSystem = useStoreState((s) => s.systems.getSystemByIdLocaly);
  const getUnitById = useStoreState((state) => state.units.getUnitById);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);

  const [addDevice, setAddDevice] = useState(false);
  const [editSubscriptions, setEditSubscriptions] = useState(false);
  const [addEditSite, setAddEditSite] = useState(false);
  const [deviceBrandSelected, setDeviceBrandSelected] = useState<number>(0);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [isFloorPlanDialogOpen, setOpenFloorPlanDialog] = useState<boolean>(false);
  const [openApproveSite, setOpenApproveSite] = useState<boolean>(false);
  const [openAutoMap, setOpenAutoMap] = useState<boolean>(false);
  const [openEditSystem, setOpenEditSystem] = useState<boolean>(false);
  const [openServiceMapping, setOpenSrvMaping] = useState<boolean>(false);
  const [openNewOtherUnit, setOpenNewOtherUnit] = useState<boolean>(false);
  const [refreshUnits, setRefreshUnits] = useState<number>(0);
  const [selectedSystemInfo, setSelectedSystemInfo] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [dateAnch, setDateAnch] = useState<any>(null);
  const [editSensors, setEditSensors] = useState<boolean>(false);
  const [editPPD, setEditPPD] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const [openAssignLineUnits, setOpenAssignLine] = useState<boolean>(false);
  const [mappingInfo, setMappingInfo] = useState<any>({ line: null, deviceVersion: null, numOfUnits: 0 });
  const [selDeviceConnDate, setDeviceDate] = useState<any>(null);
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false)
  const [isExecutingAddDevice, setIsExecutingAddDevice] = useState(false);
  const [sitesToRender, setSitesToRender] = useState<any>([]);
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [openSiteFiles, setOpenSiteFiles] = useState<string>("");
  const [allObj, setAllObj] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [siteMgmtData, setSiteMgmtData] = useState<any>({});
  const [fastDelete, setFastDelete] = useState<string>("");
  const [fastMapping, setFastMapping] = useState<boolean>(false);
  const [editSubTypeOpen, setEditSubType] = useState<boolean>(false);
  const [openPowerMappingDialog, handlePowerMappingDialog] = useState<string>("");
  const [systemSchedule, setSystemSchedule] = useState<boolean>(false);
  const [reCallMappingData, setReCallLineMappingData] = useState<boolean>(false);
  const [openMappingInProgress, setOpenMappingInProgress] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [toDeleteItem, setToDeleteItem] = useState<{ type: string, data: any } | null>(null);
  const [toDeleteItemName, setToDeleteItemName] = useState<string>("");
  const [openToDeleteItem, setOpenToDeleteItem] = useState<boolean>(false);
  const [replaceExistingDevice, setReplaceExistingDevice] = useState<boolean>(false);
  const [oldReplacedDevice, setOldReplacedDevice] = useState<string>("");
  const [newDeviceRegistrationData, setNewDeviceRegistrationData] = useState<any>({});
  const [serviceSelectedUnit, setServiceSelectedUnit] = useState<any>("");
  const { customerId, siteId: selectionsSiteId } = selections;
  const [selectedDeviceObj, setSelectedDeviceObj] = useState<any>(null);
  const [selectedSystem, setSelectedSystem] = useState<any>(null);
  const [floorPlanData, setFloorPlanData] = useState<any>(null);
  const [toDeleteFloorPlan, setToDeleteFloorPlan] = useState<any>(null);
  const [confirmReplace, setConfirmReplace] = useState<boolean>(false);
  const [customerSubscriptions, setCustomerSubscriptions] = useState<any>({});

  const { permissions: { canCreateSites = false } = {} } = getCustomer(customerId) || {};
  const name = toDeleteItem?.type === "site" ? toDeleteItem?.data?.name : toDeleteItem?.data?.serial || "";
  const trimmedName = name.replace(/\s+/g, " ").trim();
  const trimmedText = toDeleteItemName.replace(/\s+/g, " ").trim();
  const isNamesMatching = trimmedName === trimmedText;
  
 

  useEffect(() => {
    fetchData();
    formValidatorDeviceInfo = (deviceInfo: any) => {
      const validatorFields: any = {};
      const validatorFieldLabels: any = {};
      devicesRegistrationsInfoRequired[deviceInfo?.type]?.forEach((field) => {
        if (!field.isNotRequired) {
          validatorFields[field.id] = ["required"];
          validatorFieldLabels[field.id] = field.label;
        }
      });

      return validatorForm(
        {
          site: ["required"],
          type: ["required"],
          ...validatorFields
        },
        undefined,
        {
          site: selections.siteId,
          type: deviceInfo?.type,
          ...validatorFieldLabels

        }
      )(deviceInfo);
    };

    if (customerId && !isExecutingAddDevice) {
      sdkSiteSubscriptions.getCustomerSiteSubscriptions(customerId)
        .then((res: any) => {
          setCustomerSubscriptions(res);
        }).catch((err: any) => {
          setError(err);
        });
    }

  }, [customerId, isExecutingAddDevice, allUnits]);

  useEffect(() => {
    let timer: any = null;
    if (isIdle) {
      clearInterval(timer);
      timer = null;
      return;
    }

    timer = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, [isIdle]);

  const fetchData = (isDelete?: boolean) => {
    return customerId && getSiteMgmtData(customerId)
      .then((resp: any) => {
        setSiteMgmtData(resp);
        const currentStored: any = userPreferences?.serviceSitesOrderPerCustomer || {};
        const newOrder = new Set([...(customerId && currentStored[customerId] ? currentStored[customerId] : []), ...Object.keys(resp)]);
        setSitesToRender(Array.from(newOrder));
      })
      .finally(() => {
        setIsSynchronized(true);
        if (isDelete) {
          setIsDeleting(false);
        }
      });

  };

  useEffect(() => {

    if (!allSites || !allDevices || !allSystems || !allUnits || allObj.length) {
      return;
    }

    const sites = Object.values(allSites).map((site: any) => {
      return {
        name: site.name,
        label: site.name,
        type: "site",
        id: site.id
      };
    });

    const devices = Object.values(allDevices).map((device: any) => {
      const siteName = allSites[device?.site]?.name;
      const deviceSerial = device?.name;
      return {
        name: device.serial,
        label: `${siteName} - ${deviceSerial}`,
        type: "device",
        id: device.id
      };
    });

    const systems = Object.values(allSystems).map((system: any) => {
      const siteName = allSites[system.site]?.name;
      return {
        name: system.name,
        label: `${siteName} - ${system.name}`,
        type: "system",
        id: system.id
      };
    });

    const units = Object.values(allUnits).reduce((val: any, unit: any) => {
      if (+unit.type === +types.unitTypes["service"] || +unit.type === +types.unitTypes["other"]) {
        return val;
      }
      const siteName = allSites[unit.site]?.name;
      const systemName = allSystems[unit.system]?.name || t`Unassigned`;
      val.push({
        name: unit.name,
        label: `${siteName} - ${systemName} - ${unit.name}`,
        type: "unit",
        id: unit.id
      });
      return val;
    }, []);

    setAllObj([...sites, ...devices, ...systems, ...units]);

  }, [allUnits, allSystems, allSites, allDevices]);

  useEffect(() => {
    if (!openToDeleteItem || !toDeleteItem) {
      return;
    }
    if (toDeleteItem?.type === "site") {
      const siteObj = toDeleteItem.data;
      let sytsemsCount: number = 0;
      let serviceUnitsCount: number = 0;
      siteObj?.devices.forEach((device: any) => {
        sytsemsCount = sytsemsCount + device?.systems?.length;
        serviceUnitsCount = serviceUnitsCount + device?.numOfServiceUnits;
      });

      const newData = { ...siteObj, numOfDevices: siteObj?.devices?.length, numOfSystems: sytsemsCount, numOfServiceUnits: serviceUnitsCount };
      setToDeleteItem({ ...toDeleteItem, data: newData });
    } else {

      const deviceObj = toDeleteItem.data;
      const newData = { ...deviceObj, numOfSystems: deviceObj?.systems?.length, numOfServiceUnits: deviceObj?.numOfServiceUnits };
      setToDeleteItem({ ...toDeleteItem, data: newData });
    }

  }, [openToDeleteItem]);

  const openEditSubType = (deviceId: any, device: any = null) => {
    setSelectedDevice(deviceId);
    setSelectedDeviceObj(device);
    setEditSubType(!!deviceId);
  };

  const openCloseAssignLineUnits = (deviceId: any, line: any, isService: boolean = false, selectedUnit: string = "", unitType: any = "") => {
    setSelectedDevice(deviceId);
    setSelectedLine({ line, isService, selectedUnit, unitType });
    const isOpen = !!deviceId && !!line;
    setOpenAssignLine(isOpen);
  };

  const handleReplace = () => {
    setConfirmReplace(false);
    replaceDevice({ deviceId: oldReplacedDevice, data: { serial: newDeviceRegistrationData.serial, pin: newDeviceRegistrationData.pin, description: newDeviceRegistrationData.description } })
      .then(() => {
        addMessage({ message: `Successfully replaced device ${allDevices[oldReplacedDevice]?.serial} with new device ${newDeviceRegistrationData.serial}` });
        fetchData();
        onCancel();
        return;
      })
      .catch((e: any) => {
        const text = e.message || "";
        setError([text[0].toUpperCase() + text.slice(1), `Failed to replace ${allDevices[oldReplacedDevice]?.serial} with new device ${newDeviceRegistrationData.serial}. Please contact CoolAutomation Customer Service.`]);
      });
  };

  const Add = () => {
    let validate: any;
    const newDeviceInfo = {
      site: selectedSite,
      type: deviceBrandSelected
    } as any;
    if (selectedSite) {
      devicesRegistrationsInfoRequired[deviceBrandSelected]?.forEach(({ id }) => {
        newDeviceInfo[id] = newDeviceRegistrationData[id]?.replace(/\s+/g, " ")?.trim() || "";
      });

      validate = formValidatorDeviceInfo(newDeviceInfo);

      if (!validate.check()) {
        const errors = validate.errors.all();
        const allErrors = _(errors)
          .values()
          .flatten()
          .value();
        if (replaceExistingDevice && !oldReplacedDevice) {
          allErrors.push("The Replaced device serial is required");
        }
        allErrors[0] && setError(allErrors);
        return;
      }

      if (replaceExistingDevice && oldReplacedDevice) {
        setConfirmReplace(true);
        return;
      }
      setIsExecutingAddDevice(true);

      addNewDeviceToSite({ ...newDeviceInfo })
        .then((data: any) => {
          getSystems()
          getAllSystemCustomized()
          fetchAllUnits()
        })
        .catch((e: any) => {
          const text = e.message || "";
          setError(text[0].toUpperCase() + text.slice(1));
        }).finally(() => {
          setIsExecutingAddDevice(false);
          //if selected site has hvacControl = true that means its a pending site/or service app site(not mainly prof)
          //for this site adding device is allowed but with a limited view so we skip all other steps
          if (siteMgmtData[selectedSite]?.hvacControl) {
            fetchData();
          }
          onCancel();
        });
    } else {
      onCancel();
    }
  };

  const onCancel = () => {
    setDeviceBrandSelected(0);
    setAddDevice(false);
    setNewDeviceRegistrationData({});
    setSelectedSite(null);
    setOldReplacedDevice("");
    setReplaceExistingDevice(false);
  };

  const approveSite = () => {
    approveSiteAPI(selectedSite)
      .then(() => {
        fetchData();
        setOpenApproveSite(false);
      })
      .catch((err: any) => {
        setOpenApproveSite(false);
        setError(err?.message);
      });

  };

  const changeSystemMode = (systemId: string, mode: "HEAT" | "COOL") => {
    const val: any = Object.keys(types.operationModesExtended).filter((modeVal: any) => types.operationModesExtended[modeVal] === mode)[0];
    changeSystemOperationMode({ id: systemId, data: +val })
      .then(() => {
        fetchData();
      });
  };

  const changeSystemStatus = (systemId: string, currentStatus: number) => {
    const val: any = Object.keys(types.operationStatuses).filter((statusVal: any) => +statusVal !== +currentStatus)[0];
    systemTogglePower({ id: systemId, data: +val })
      .then(() => {
        fetchData();
      });
  };

  const openAddDevice = (id: string) => {
    setAddDevice(true);
    setSelectedSite(id);
  };

  const openSubscriptions = (id: string) => {
    setEditSubscriptions(true);
    setSelectedSite(id);
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder: any = reorder(
      sitesToRender,
      result.source.index,
      result.destination.index
    );

    setSitesToRender(newOrder);
    const currentStored = userPreferences?.serviceSitesOrderPerCustomer || {};
    const customer: string = customerId || "";

    if (!currentStored[customer]) {
      currentStored[customer] = {};
    }

    currentStored[customer] = newOrder;
    updateUserPreferences({ serviceSitesOrderPerCustomer: currentStored });
  };

  const deletItem = (type: string, idObj: any) => {
    if (type === "site") {
      setIsDeleting(true);
      deleteSite(idObj)
        .then(() => {
          closeItemDeleteDialog();
          setIsDeleting(false);
        });
      const { [idObj.id]: _, ...restOfSites } = siteMgmtData;
      setSiteMgmtData(restOfSites);
    } else {
      setIsDeleting(true);
      deleteDevice(idObj)
        .then(() => {
          closeItemDeleteDialog();
          fetchData(true); //it has the setIsDelete(false)
          // fetchAllUnits to update the top menu
          fetchAllUnits()
        });
    }
  };

  const closeItemDeleteDialog = () => {
    setOpenToDeleteItem(false);
    setToDeleteItem(null);
    setToDeleteItemName("");
  };

  const openDateMenu = (deviceId: any, e?: any, date?: any) => {
    setSelectedDevice(deviceId);
    setDateAnch(deviceId ? e?.currentTarget : null);
    setDeviceDate(date || null);
  };

  const saveDeviceDate = () => {
    updateDevice({ deviceId: selectedDevice, data: { registrationDate: selDeviceConnDate?.getTime() || new Date().getTime() } })
      .then(() => {
        fetchData();
        openDateMenu(null);
      });
  };

  const saveDeviceLocation = (id: string, location: string) => {
    updateDevice({ deviceId: id, data: { description: location } })
      .then(() => {
        fetchData();
        openDateMenu(null);
      });
  };

  const openEditSensors = (deviceId: any) => {
    setSelectedDevice(deviceId);
    setEditSensors(!!deviceId);
  };

  const openEditPPD = (deviceId: any) => {
    setSelectedDevice(deviceId);
    setEditPPD(!!deviceId);
  };

  const setOpenUnmappedUnitsDialog = (deviceId: any) => {
    setSelectedDevice(deviceId);
    setFastMapping(!!deviceId);
  };

  const setOpenDisconnectedDialog = (deviceId: any, type: string = "") => {
    setSelectedDevice(deviceId);
    setFastDelete(type);
  };

  const startAutoMapping = async () => {
    if (!selectedDevice || !mappingInfo.line || !mappingInfo.deviceVersion) {
      openMappingModal(null);
      return;
    }
    await mapLineIndoors({ deviceId: selectedDevice, lineId: `${mappingInfo.line}`, data: {} });
    setReCallLineMappingData(!reCallMappingData);
    fetchData();
    openMappingModal(null);
  };
  const openSystemSchedule = (systemId: any, deviceId: any, system: any = null) => {
    setSelectedSystemInfo(systemId ? { selectedSystem: systemId, selectedDevice: deviceId } : null);
    setSelectedSystem(system);
    setSystemSchedule(!!systemId);
  };

  const searchComponent = (
    <Autocomplete
      id="combo-box-demo"
      options={allObj}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label || ""}
      groupBy={(option) => option.type}
      value={""}
      inputValue={searchTerm}
      classes={{
        clearIndicator: classes.searchIcon,
        popupIndicator: classes.searchIcon,
        popper: classes.autoCompletePoper,
        paper: classes.autoCompletePaper,
        listbox: classes.autoCompleteItem,
        noOptions: classes.autoCompleteItem,
        groupLabel: classes.autoCompleteGroup,
        groupUl: classes.autoCompleteGroupUl

      }}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps, endAdornment: <Search /> }} />}
      onChange={(e: any, val: any) => {
        setSearchTerm("");
        if (!val) {
          return;
        }

        let type = val?.type;
        let id = val?.id;

        if (val.type === "device") {
          const siteId = allDevices[val.id]?.site;
          id = siteId;
          type = "site";
        }
        updateSelections({ type, data: id });

        if (type === "system") {
          const idSystem = val?.id;
          const system = getSystem(idSystem);
          const idDevice = system.device;
          openAddEditSystem(idDevice, idSystem, true);
        }
        if (type === "unit") {
          const idUnit = val?.id;
          const unit = getUnitById(idUnit);
          const unitType = unit?.type;
          const idDevice = unit?.device;
          if (!unit?.system) {
            const lineId = unit?.line;
            openCloseAssignLineUnits(idDevice, lineId, true, idUnit, unitType);
            return;
          }
          const sytemId = unit?.system;
          openAddEditSystem(idDevice, sytemId, true, idUnit, unitType);
        }
      }}
    />
  );

  const openApprovalDialog = (siteId: any) => {
    setSelectedSite(siteId);
    setOpenApproveSite(!!siteId);
  };

  const openAddEditSystem = (deviceId: any, systemId?: any, isService: boolean = true, selectedUnit: string = "", unitType: any = "") => {
    setSelectedSystemInfo(systemId ? { selectedSystem: systemId, selectedDevice: deviceId, isService, selectedUnit, unitType } : null);
    setOpenEditSystem(!!systemId);
  };

  const openSrvMapDialog = (isOpen: boolean, selectedUnit: string = "") => {
    setOpenSrvMaping(!!isOpen);
    setServiceSelectedUnit(selectedUnit);
  };

  const openAddNewOtherDialog = (deviceId: any, systemLine?: any) => {
    setSelectedDevice(deviceId);
    setMappingInfo({ line: systemLine, deviceVersion: null, numOfUnits: 0 });
    setOpenNewOtherUnit(!!deviceId);
  };

  const openMappingModal = (deviceId: any, line?: any, deviceVersion?: any, numOfUnits?: any) => {
    setSelectedDevice(deviceId);
    setMappingInfo({ line, deviceVersion, numOfUnits: numOfUnits || 0 });
    setOpenAutoMap(!!deviceId);
  };

  const openAddEditSite = (siteId: any) => {
    setSelectedSite(siteId);
    setAddEditSite(!!siteId);
  };

  const addOtherUnit = (deviceId: string, line: number, name: string) => {
    createOtherUnit({ deviceId, line, name }).then(() => {
      setRefreshUnits(refreshUnits + 1);
      openAddNewOtherDialog(null);
    });
  };

  const openFloorPlanDialog = (siteId: any, data = {}) => {
    setSelectedSite(siteId);
    setOpenFloorPlanDialog(!!siteId);
    setFloorPlanData({
      name: "",
      img: null,
      isEdit: false,
      ...data
    });
  };

  const deleteFloorPlan = (id: string) => {
    sdkSite.deleteFloorPlan(id)
      .then(() => fetchData())
      .finally(() => setToDeleteFloorPlan(null));
  };

  const actionsProp = {
    changeSystemMode,
    changeSystemStatus,
    fetchData,
    openAddEditSite,
    openAddEditSystem,
    openSrvMapDialog,
    openAddDevice,
    setAddEditSite,
    openApprovalDialog,
    openDateMenu,
    openEditSensors,
    openEditPPD,
    openMappingModal,
    setError,
    openAddNewOtherDialog,
    setOpenDisconnectedDialog,
    setOpenUnmappedUnitsDialog,
    openEditSubType,
    openCloseAssignLineUnits,
    openSystemSchedule,
    setOpenMappingInProgress,
    setIsDeleting,
    setToDeleteItem,
    setOpenToDeleteItem,
    saveDeviceLocation,
    openFloorPlanDialog,
    setToDeleteFloorPlan,
    openSubscriptions
  };

  const brandNames = Object.entries(deviceBrands || {})
    .reduce((acc: any, [brandName, brandNo]) => {
      acc["" + brandNo] = brandName[0].toUpperCase() + brandName.slice(1);
      return acc;
    }, {});

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Site Management"]}
          hideSystemSelection
          hideUnitSelection
          searchComponent={searchComponent}
          screenTitle="siteMgmt"
          countControlUnits={true}
        />

        {!customerId ?
          <FilterRequire type={t`customer`} /> :
          <>

            {isDeleting && <Loader showLoader={true} />}
            {isSynchronized ? (
              <Fragment>
                <div className={classes.headerButtons}>
                  <Tooltip title={t`Click here to create a new Site. You will then have the option to add devices (and HVAC systems) to the site.`}>
                    <div>
                      <Button
                        disabled={!canCreateSites}
                        className={classes.headerButton}
                        onClick={() => openAddEditSite(true)}
                      >
                        {t`Add New Site`}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
                <Grid container className={classes.sitesContainer}>
                  <DragDropContext
                    onDragEnd={onDragEnd}
                  >
                    <Droppable droppableId="droppable" type="sitesDropdown">
                      {(provided: any) => (
                        <div ref={provided.innerRef} className={classes.siteviewHolder}>
                          {sitesToRender?.map(
                            (siteId: any, index: number) => {
                              let site = siteMgmtData[siteId];
                              if (!site) {
                                return null;
                              }
                              const { isServiceSite } = site;
                              if (selectionsSiteId && selectionsSiteId !== siteId || site.customerId !== selections.customerId) {
                                return null;
                              }
                              site = {
                                ...site,
                                embedUrl: {
                                  enableURL: allSites[siteId].enableURL,
                                  URL: allSites[siteId].URL,
                                  urlPassword: allSites[siteId].urlPassword,
                                  urlUsername: allSites[siteId].urlUsername,
                                }
                              }
                              return (
                                <Draggable
                                  key={`site-${siteId}`}
                                  draggableId={`site-${siteId}`}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <Grid item xs={12} className={classes.siteViewContainer} key={siteId}>
                                        <div className={classes.dragContainer} {...provided.dragHandleProps} ><MoreVert className={classes.whiteMoreVert} /></div>
                                        <div className={classes.siteHolder}>
                                          {isServiceSite ?
                                            <SiteView
                                              siteId={siteId}
                                              openFoldersDialog={setOpenSiteFiles}
                                              site={site}
                                              enableAirQuality={allSites[siteId]?.enableAirQuality}
                                              airQualityParams={allSites[siteId]?.airQualityParams}
                                              actions={actionsProp}
                                              serviceParams={serviceParams}
                                              handlePowerMappingDialog={handlePowerMappingDialog}
                                              isExecutingAddDevice={isExecutingAddDevice}
                                            /> :
                                            <SiteViewMgmt
                                              siteId={siteId}
                                              openFoldersDialog={setOpenSiteFiles}
                                              site={site}
                                              actions={actionsProp}
                                              handlePowerMappingDialog={handlePowerMappingDialog}
                                              enableAirQuality={allSites[siteId]?.enableAirQuality}
                                              airQualityParams={allSites[siteId]?.airQualityParams}
                                              serviceParams={serviceParams}
                                            />}
                                        </div>
                                      </Grid>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>
              </Fragment>
            ) : (
              <Grid className={classes.progress}>
                <CircularProgress />
              </Grid>
            )}

            <Dialog
              onClose={onCancel}
              open={addDevice}
              classes={{ paper: classes.dialogPaper }}
            >
              <div className={classes.dialogHeader}>
                <Typography className={classes.headerTitle}>{(deviceBrandSelected !== 0 && brandNames[deviceBrandSelected] + t` Cloud integration`) || t`Add Device`}</Typography>
                <IconButton disableRipple className={classes.iconBtnStyle} onClick={onCancel}>
                  <Close />
                </IconButton>
              </div>

              <RegisterDevice
                className="registerDevice"
                site={selectedSite}
                siteDevices={siteMgmtData[selectedSite]?.devices}
                deviceBrandSelected={deviceBrandSelected}
                setDeviceBrandSelected={setDeviceBrandSelected}
                devicesRegistrationsInfoRequeried={devicesRegistrationsInfoRequired}
                newDeviceRegistrationData={newDeviceRegistrationData}
                setNewDeviceRegistrationData={setNewDeviceRegistrationData}
                deviceBrands={deviceBrands}
                isLoading={isExecutingAddDevice}
                setReplaceExistingDevice={setReplaceExistingDevice}
                setOldReplacedDevice={setOldReplacedDevice}
                replaceExistingDevice={replaceExistingDevice}
                oldReplacedDevice={oldReplacedDevice}
                confirmCheck={confirmCheck}
                setConfirmCheck={setConfirmCheck}
              />

              <div className={classes.AddDeviceActions}>
                <Button
                  white
                  onClick={onCancel}
                  width={130}
                  className={classes.yesBtn}
                >
                  {t`Cancel`}
                </Button>
                <Button onClick={Add}
                  autoFocus={true}
                  width={130}
                  marginRight
                  disabled={!confirmCheck}
                >
                  {t`Add`}
                </Button>
              </div>

            </Dialog>
            {openSiteFiles && <FoldersTableDialog siteId={openSiteFiles} site={siteMgmtData[openSiteFiles]} openFoldersDialog={setOpenSiteFiles} />}


          </>}
      </div>
      {!!selectedSite && addEditSite && <AddEditSite item={siteMgmtData[selectedSite]} siteId={selectedSite} close={() => {
        setSelectedSite(null);
        setAddEditSite(false);
      }} actions={actionsProp} />}
      {selectedSystemInfo && openEditSystem &&
        <AddEditSystem setRefreshUnits={setRefreshUnits} reCallMappingData={reCallMappingData} selectedSystemInfo={selectedSystemInfo} close={() => openAddEditSystem(null, null)} actions={actionsProp} refreshUnits={refreshUnits} isService={selectedSystemInfo?.isService} />
      }
      {selectedDevice && selectedLine && openAssignLineUnits &&
        <AssignLineUnits setRefreshUnits={setRefreshUnits} reCallMappingData={reCallMappingData} selectedDevice={selectedDevice} selectedLine={selectedLine?.line} selectedUnit={selectedLine?.selectedUnit} close={() => openCloseAssignLineUnits(null, null)} actions={actionsProp} refreshUnits={refreshUnits} isService={selectedLine?.isService} unitType={selectedLine?.unitType} />
      }
      {selectedSystemInfo && openServiceMapping &&
        <SystemServiceMapping setRefreshUnits={setRefreshUnits} reCallMappingData={reCallMappingData} selectedSystemInfo={selectedSystemInfo} close={() => setOpenSrvMaping(false)} actions={actionsProp} refreshUnits={refreshUnits} isService={selectedSystemInfo?.isService} selectedUnit={serviceSelectedUnit} />
      }

      {
        selectedSite && openApproveSite &&
        <Dialog
          open={selectedSite && openApproveSite}
          onClose={() => openApprovalDialog(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
        >
          <div className={classes.titleContent}>
            <Typography className={classes.headerTitle}>{t`Approval Confirmation`}</Typography>
            <IconButton disableRipple onClick={() => openApprovalDialog(null)} className={classes.overWriteIcon}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            <Typography className={classes.DialogContentText}>
              {t`Do you approve site ` + `${siteMgmtData[selectedSite]?.name} ` + t`creation? `}
            </Typography>
          </div>
          <div className={classes.dialogActions}>

            <Button
              marginRight
              autoFocus={true}
              onClick={() => openApprovalDialog(null)}
              width={130}
            >
              {t`No`}
            </Button>

            <Button
              onClick={() => approveSite()}
              white
              width={130}
            >
              {t`Yes`}
            </Button>

          </div>
        </Dialog>
      }
      {
        dateAnch && selectedDevice &&
        <Popover
          open={dateAnch && selectedDevice}
          anchorEl={dateAnch}
          onClose={() => openDateMenu(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
        >
          <DatePicker variant={"static"} value={selDeviceConnDate} onChange={(date: any) => setDeviceDate(date)} />
          <div className={classes.dialogActions}>
            <Button onClick={() => saveDeviceDate()}
              autoFocus={true}
              width={130}
              className={classes.yesBtn}
            >
              {t`Save`}
            </Button>

            <Button
              onClick={() => openDateMenu(null)}
              white
              width={130}
            >
              {t`Cancel`}
            </Button>
          </div>
        </Popover>
      }

      {
        selectedDevice && editSensors &&
        <ManuallySetSensors
          title={"Auxiliary devices"}
          closeModal={() => openEditSensors(null)}
          deviceId={selectedDevice}
        />
      }

      {
        selectedDevice && editPPD &&
        <ManuallySetPPDs
          deviceId={selectedDevice}
          closeModal={() => openEditPPD(null)}
          actions={actionsProp}
        />
      }
      {
        selectedDevice && fastDelete &&
        <DisconnectedUnitsDialog
          deviceId={selectedDevice}
          close={() => setOpenDisconnectedDialog("")}
          actions={actionsProp}
          type={fastDelete}
        />
      }
      {
        selectedDevice && fastMapping &&
        <UnmappedUnitsDialog
          deviceId={selectedDevice}
          close={() => setOpenUnmappedUnitsDialog(null)}
          actions={actionsProp}
        />
      }
      {openPowerMappingDialog &&
        <PowerMetersMappingDialog
          site={siteMgmtData[openPowerMappingDialog]}
          refresh={fetchData}
          close={() => handlePowerMappingDialog("")}
        />}

      {
        selectedDevice && openAutoMap && mappingInfo.line && mappingInfo.deviceVersion &&
        <Dialog
          open={selectedDevice && openAutoMap}
          onClose={() => openMappingModal(null)}
          aria-labelledby="mapping-dialog-title"
          aria-describedby="mapping-dialog-description"
          maxWidth="sm"
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{(mappingInfo.deviceVersion >= 2) ? t`Automatic unit address mapping` : t`Auto Mapping in Progress...`}</Typography>
            <IconButton onClick={() => openMappingModal(null)} className={classes.iconBtnStyle}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            {mappingInfo.deviceVersion >= 2 ?
              <Typography style={{ whiteSpace: "pre-line" }}>
                <Box fontWeight="fontWeightMedium" display="inline">{t`Be Advised: `}</Box>
                {t`We estimate the mapping will take about` + ` ${Math.ceil(mappingInfo.numOfUnits / 16) * 6} ` + t`minutes. During the process, the units will be switched into fan mode and then restored to their original status upon completion (on/off, mode, setpoint).
                Please avoid modifying the units' settings until it is done.
                \nDo you wish to initiate address mapping?`}
              </Typography>
              :
              <Typography style={{ whiteSpace: "pre-line" }}>
                {t`Auto mapping requires upgrade to your device FW. Please contact CoolAutomation Support to upgrade`}
              </Typography>
            }
          </div>
          <div className={classes.actionsHolder}>
            {
              mappingInfo.deviceVersion >= 2 ? (

                <>
                  <Button
                    onClick={() => openMappingModal(null)}
                    autoFocus={true}
                    width={100}
                    marginRight
                  >{t`No`}
                  </Button>
                  <Button
                    onClick={startAutoMapping}
                    white
                    width={100}
                  >
                    {t`Yes`}
                  </Button>
                </>) :
                <Button
                  onClick={() => openMappingModal(null)}
                  width={100}
                >
                  {t`OK`}
                </Button>
            }

          </div>
        </Dialog>
      }

      <AddEditFloorPlan
        actions={actionsProp}
        siteId={selectedSite}
        data={floorPlanData}
        isOpen={isFloorPlanDialogOpen && selectedSite}
      />

      {
        openNewOtherUnit &&
        mappingInfo?.line &&
        selectedDevice &&
        <CreateOtherUnitPopUp device={selectedDevice} line={mappingInfo?.line} close={() => openAddNewOtherDialog(null)} onAdd={addOtherUnit} />
      }

      {
        !!error &&
        <Dialog
          open={!!error}
          onClose={() => setError(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t`Error`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {Array.isArray(error) ? error.map((err: any, i: number) => <div key={i}>{err}</div>) : error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setError(null)} color="primary">
              {t`OK`}
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        !!openMappingInProgress &&
        <Dialog
          open={!!openMappingInProgress}
          onClose={() => setOpenMappingInProgress(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Mapping In Progress`}</Typography>
            <IconButton onClick={() => setOpenMappingInProgress(false)} className={classes.iconBtnStyle}>
              <Close />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t`Automatic Address Mapping is in progress, please wait.`}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }

      {
        selectedDevice && editSubTypeOpen &&
        <EditSubType device={selectedDeviceObj} isOpen={selectedDevice && editSubTypeOpen} deviceId={selectedDevice} close={openEditSubType} setError={setError} />
      }
      {
        selectedSystemInfo?.selectedSystem && systemSchedule &&
        <SystemSchedule
          isOpen={selectedSystemInfo?.selectedSystem && systemSchedule}
          systemId={selectedSystemInfo?.selectedSystem}
          system={selectedSystem}
          close={() => { setSystemSchedule(false); fetchData(); }}
        />
      }
      {!!toDeleteItem &&
        <Dialog
          open={!!toDeleteItem}
          onClose={closeItemDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
        >
          <div className={classes.titleContent}>
            <Typography className={classes.headerTitle}>{t`Delete ` + toDeleteItem.type}</Typography>
            <IconButton disableRipple onClick={closeItemDeleteDialog} className={classes.overWriteIcon}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            <Typography className={clsx(classes.boldText, classes.DialogContentText)}>
              {t`You are trying to delete ` + toDeleteItem.type}
              <span className={classes.redItemName}>{toDeleteItem?.type === "site" ? ` ` + toDeleteItem.data.name : ` ` + toDeleteItem.data.serial}</span>
            </Typography>
            {(!!toDeleteItem.data.numOfDevices || !!toDeleteItem.data.numOfSystems || !!toDeleteItem.data.numOfServiceUnits) &&
              <div className={classes.deleteInfoBlock}>
                <Typography className={classes.DialogContentText}>{t`Note this ` + toDeleteItem.type + ` includes:`}</Typography>
                {toDeleteItem?.type === "site" && !!toDeleteItem.data.numOfDevices && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{toDeleteItem.data.numOfDevices}</Typography>
                  {t` devices`}
                </Typography>}
                {!!toDeleteItem.data.numOfSystems && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{toDeleteItem.data.numOfSystems}</Typography>
                  {t` systems`}
                </Typography>}
                {!!toDeleteItem.data.numOfServiceUnits && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{toDeleteItem.data.numOfServiceUnits}</Typography>
                  {t` units`}
                </Typography>}
              </div>}
            <div className={classes.deleteInfoBlock}>
              <Typography className={clsx(classes.DialogContentText, classes.boldText)}>{t`To proceed, please type precisely the ` + toDeleteItem.type + ` ${toDeleteItem.type === "device" ? t`serial number` : t`name`} in the box below, and click "Delete"`}</Typography>
              <TextField
                variant={"outlined"}
                name={toDeleteItem?.type === "site" ? "Site Name" : "Device Serial"}
                label={toDeleteItem?.type === "site" ? "Enter Site Name" : " Enter Device Serial"}
                placeholder={toDeleteItem?.type === "site" ? "Site Name" : "Device Serial"}
                value={toDeleteItemName}
                className={classes.deleteNameInput}
                onChange={(e: any) => setToDeleteItemName(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.actionsHolder}>
            <Button
              className={classes.yesBtn}
              onClick={closeItemDeleteDialog}
              white
              width={130}
              marginRight
            >
              {t`Cancel`}
            </Button>

            <Button
              autoFocus={true}
              onClick={() => deletItem(toDeleteItem?.type, { "id": toDeleteItem.data.id })}
              width={130}
              disabled={!isNamesMatching}
            >
              {t`Delete`}
            </Button>
          </div>
        </Dialog>
      }
      {!!toDeleteFloorPlan &&
        <Dialog
          open={!!toDeleteFloorPlan}
          onClose={() => setToDeleteFloorPlan(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
        >
          <div className={classes.titleContent}>
            <Typography className={classes.headerTitle}>{t`Delete Floor Plan`}</Typography>
            <IconButton disableRipple onClick={() => setToDeleteFloorPlan(null)} className={classes.overWriteIcon}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            <Typography className={clsx(classes.boldText, classes.DialogContentText)}>
              {t`Are you sure you would like to delete the floor plan?`}
            </Typography>
          </div>
          <div className={classes.actionsHolder}>
            <Button
              className={classes.yesBtn}
              onClick={() => setToDeleteFloorPlan(null)}
              white
              width={130}
              marginRight
            >
              {t`Cancel`}
            </Button>

            <Button
              autoFocus={true}
              onClick={() => deleteFloorPlan(toDeleteFloorPlan)}
              width={130}
              disabled={!isNamesMatching}
            >
              {t`Delete`}
            </Button>
          </div>
        </Dialog>
      }
      {!!confirmReplace &&
        <Dialog
          open={!!confirmReplace}
          onClose={() => setConfirmReplace(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}
        >
          <div className={classes.titleContent}>
            <Typography className={classes.headerTitle}>{t`Reaplce device ` + allDevices[oldReplacedDevice]?.serial}</Typography>
            <IconButton disableRipple onClick={() => setConfirmReplace(false)} className={classes.overWriteIcon}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.dialogContent}>
            <Typography className={clsx(classes.boldText, classes.DialogContentText)}>{t`Starting a process to replace an existing device with a new similar device. Please ensure the following before proceeding:`}</Typography>
            <Typography className={classes.DialogContentText}>{t`- Do not remove the Old device` + ` (${allDevices[oldReplacedDevice]?.serial}) ` + t`from the App`}</Typography>
            <Typography className={classes.DialogContentText}>{t`- Power OFF the old device`}</Typography>
            <Typography className={classes.DialogContentText}>{t`- Physically replace the old device with the new device, following exactly the same wiring/ports scheme you had with the old device.`}</Typography>
            <Typography className={classes.DialogContentText}>{t`- An automatic process will run and map the old device entities to the new one`}</Typography>
            <Typography className={classes.DialogContentText}>{t`Click next to proceed or Back to cancel`}</Typography>
          </div>
          <div className={classes.actionsHolder}>
            <Button
              className={classes.yesBtn}
              onClick={() => setConfirmReplace(false)}
              white
              width={130}
              marginRight
            >
              {t`Cancel`}
            </Button>

            <Button
              autoFocus={true}
              onClick={() => handleReplace()}
              width={130}
            >
              {t`Next`}
            </Button>
          </div>
        </Dialog>
      }

      {editSubscriptions && <SiteSubscriptions
        handleCancel={setEditSubscriptions}
        open={editSubscriptions}
        site={siteMgmtData[selectedSite]}
        customerSubscriptions={customerSubscriptions}
      />}
    </div>
  );
};
export default SiteManagement;
