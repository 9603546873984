import {
  Dialog,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import _ from "lodash";
  import React, { useEffect, useState } from "react";
  import { t } from "ttag";
  import clsx from "clsx";
  import FilterRequire from "../../components/FilterRequire/FilterRequire";
  import Header from "../../components/Header/Header";
  import Loading from "../../components/Loading/Loading";
  import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
  
  import { useStoreActions, useStoreState } from "../../models/RootStore";
  import useStyles from "./BacnetDeviceObjectList.style";
  import { IBacnetDeviceObject } from "@models/BacnetDevices";
  import Button from "@cool_widgets/Button";
  
  const BacnetDeviceObjectList: React.FC = (props: any) => {
    const classes = useStyles();
  
    const isInitialized = useStoreState((s) => s.isInitialized);
    const selections = useStoreState((state) => state.selections.selections);
    const addMessage = useStoreActions((action) => action.errorMessage.addMessage);

    const {
        fetchSiteBacnetDevices,
        fetchCustomerBacnetDevices,
        getBacnetDeviceObjectsByDeviceId,
        discoverBacnetDeviceObjectsByDeviceId
      } = useStoreActions((action) => action.bacnetDevices);

    const { siteId, customerId, bacnetDeviceId } = selections;
    const [bacnetDeviceObjects, setBacnetDeviceObjects] = useState<Record<string,IBacnetDeviceObject>>({});
    const [selectedBacnetObject, setSelectedBacnetObject] = useState<IBacnetDeviceObject| null>(null);
    const [discoverDeviceObjects, setDiscoverDeviceObjects] = useState<boolean>(false);

    const sortedObjects = _.orderBy(Object.values(bacnetDeviceObjects), [(bacnetDeviceObject: IBacnetDeviceObject) => bacnetDeviceObject.name?.toLowerCase()], ["asc"]);

    useEffect(() => {
      const fetchBacnetDevices = async () => {
        try {
            if (!customerId) {
              return;
            }
            if (!siteId) {
                await fetchCustomerBacnetDevices(customerId);
            } else {
                await fetchSiteBacnetDevices(siteId);
            }
        } catch (err: any) {
            addMessage({ message: err.message });
        }
      };

      fetchBacnetDevices();
    }, [siteId,customerId]);

    useEffect(() => {
        if (!bacnetDeviceId) {
            setBacnetDeviceObjects({});
        }else{
            getBacnetDeviceObjectsByDeviceId(bacnetDeviceId).then(
                (response:any) => setBacnetDeviceObjects(response)
            )
        }
    }, [bacnetDeviceId]);

    useEffect(() => {
      if (discoverDeviceObjects && bacnetDeviceId) {
        discoverBacnetDeviceObjectsByDeviceId(bacnetDeviceId).then(
          (response:any) => setBacnetDeviceObjects(response)
        ).finally(setDiscoverDeviceObjects(false));
      }
    });

    const closeDetails = () => {
      setSelectedBacnetObject(null)
    }

    if (!isInitialized) { return <Loading />; }

    return (
        <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site`, unit: t`Select BACnet device` }}
          hideUnitSelection
          hideSystemSelection
          showBacnetDevices
          screenTitle="bacnetDeviceObjects"
        />
        {!siteId || !bacnetDeviceId ? <FilterRequire type={t`bacnet device`} /> :
          <>
          <div className={classes.headerButtons}>
              <Button
                disabled={false}
                width={235}
                onClick={() => setDiscoverDeviceObjects(true)}
              >
                {t`Discover Objects`}
              </Button>
            </div>
            <Paper elevation={0} className={classes.paperTableContainer}>
            
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                    <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Object Id`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Name`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Description`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedObjects.map((bacnetDeviceObject) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={bacnetDeviceObject.id}
                          onDoubleClick={(e)=> {
                            setSelectedBacnetObject(bacnetDeviceObject)
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            {bacnetDeviceObject.objectId}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                            align="left"
                          >
                            {bacnetDeviceObject.name}
                          </TableCell>
                          
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                            align="left"
                          >
                            {bacnetDeviceObject.description}
                          </TableCell>
                          
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {selectedBacnetObject && <Dialog
              open={true}
              onClose={closeDetails}
              aria-labelledby="create-other-unit-dialog"
              disableEnforceFocus
              fullScreen={true}
              classes={{ paper: classes.dialogPaper }}
            >
              <div id="dialogContent" className={classes.dialogStyle}>
              <div style={{ position: "relative" }}>
              <div className={classes.boxContent}>
              <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                      <TableRow>
                      <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="right"
                        >{t`Property`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Value`}</TableCell>
                      </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(selectedBacnetObject.bacnetProps).map((key)=> (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                      align="right"
                    >
                      {key}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: clsx(classes.overWritePadding, classes.bigWidth) }}
                      align="left"
                    >
                      {JSON.stringify(selectedBacnetObject.bacnetProps[key])}
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
                </Table>
              </TableContainer>
              </div>

              <div className={classes.actionsContainer}>
                <Button width={150} white marginRight onClick={closeDetails}>{t`Close`}</Button>
              </div>
            
              </div>
            
            </div>
            </Dialog>}
        </>}
          
      </div>
    </div>
    );
    
  }

  export default BacnetDeviceObjectList