// prettier-ignore
import { CommercialApplication as commercialSdk, File as fileSdk, Site as siteSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { makeFullAddressForSite } from "../utils/CommonUtils";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";
import { IUnit } from "./Units";

export interface ISite {
  id: string;
  name: string;
  description?: string;
  country?: string;
  city?: string;
  state?: string;
  address?: string;
  postalCode?: string;
  timezone?: string;
  devices: string[];
  zones: string[];
  triggers: string[];
  alerts: string[];
  customer: string;
  unitSupportedOperationModes: number[];
  unitSupportedFanModes: number[];
  unitSupportedSwingModes: number[];
  unitTemperatureLimits?: any;
  shouldAutoLimit?: boolean;
  role: any;
  lat: any;
  lon: any;
  serviceStatus: number;
  subscriptions?: string[];
  lastTemperature?: any;
  isServiceSite: boolean;
  predictiveMaintenance?: boolean;
  hvacAdvancedOperations?: boolean;
  hvacControl?: boolean;
  commercialhvacControl?: boolean;
  powerPackageEnabled?: boolean;
  basicRemoteDiagnostics?: boolean;
  enableAirQuality?: boolean;
  airQualityParams?: any;
  powerReportStructure?: number;
  canAddTenat?: boolean;
  canDeleteTenant?: boolean;
  canAddReportScheduler?: boolean;
  canDeleteReportScheduler?: boolean;
  canAddAutoChangeOverRule?: boolean;
  canDeleteAutoChangeOverRule?: boolean;
  canUpdateAutoChangeOverRule?: boolean;
  canAddAutomationRule?: boolean;
  canDeleteAutomationRule?: boolean;
  canUpdateAutomationRule?: boolean;
  supportedReportTypes?: number[];
  canAddPowerMeter?: boolean;
  defaultFloorPlan?: any;
  permissions?: any;
  enableURL?: boolean;
  URL?: string;
  urlUsername?: string;
  urlPassword?: string;
}

export interface ISiteMap {
  [key: string]: ISite;
}

export interface ISiteStructureSample {
  headers: {title: string, width: number}[];
  rows: (string | number)[][];
}

export interface ISiteAlertsCount {
  systemError: number;
  indoorError: number;
  maintenance: number;
  notConnected: number;
  anomalies: number;
}

export interface ISitesModel {
  allSites: ISiteMap;
  warnedSites: ISite[];
  blockedSites: ISite[];
  siteStructureSample: ISiteStructureSample;
  sitesFlags: {
    [index: string]: any | undefined;
  };
  initialize: Action<
    ISitesModel,
    {
      all: any;
      warned: any;
      blocked: any;
    }
  >;
  onInitialized: ActionOn<ISitesModel, IRootStoreModel>;
  getSite: Computed<ISitesModel, (id?: string | null) => ISite | undefined>;
  getSiteName: Computed<ISitesModel, (id?: string) => string>;
  getSiteFullAddress: Computed<ISitesModel, (id: string) => string>;
  getSiteAlerts: Computed<
    ISitesModel,
    (id: string, type: "all" | "unresolved") => IAlert[],
    IRootStoreModel
  >;
  getSiteByName: Computed<
    ISitesModel,
    (name: string | undefined) => ISite | null
  >;
  changeSitePower: Thunk<ISitesModel, { siteId: string | null; state: number }>;
  getSiteById: Thunk<ISitesModel, string>;
  approveSite: Thunk<ISitesModel, string>;
  getSiteMgmtData: Thunk<ISitesModel, string>;
  changeSiteSetpoints: Thunk<
    ISitesModel,
    { siteId: string | null; setpoint: number }
  >;
  getPowerUsage: Thunk<
    ISitesModel,
    {
      siteId: any;
      startTime: any;
      endTime: any;
      resolution: any;
      distributeExcess: boolean;
    }
  >;
  getPowerUsageAsync: Thunk<
    ISitesModel,
    {
      siteId: any;
      startTime: any;
      endTime: any;
      resolution: any;
      distributeExcess: boolean;
    }
  >;
  getPowerUsageRecord: Thunk<ISitesModel, { recordId: string }>;
  getEstimatedPowerUsage: Thunk<
    ISitesModel,
    {
      siteId: any;
      startTime: any;
      endTime: any;
      resolution: any;
    }
  >;
  getSiteSystems: Computed<
    ISitesModel,
    (id: string) => any[] | any,
    IRootStoreModel
  >;
  getSiteFlags: Thunk<ISitesModel>;
  setSiteFlags: Action<ISitesModel, any>;
  fetchSiteSystems: Thunk<ISitesModel, string>;
  getSitePowerMeters: Thunk<ISitesModel, { id: string; type?: number }>;
  getDuplicateDaikinAirnetsUnits: Thunk<ISitesModel, string>;
  getMissingDaikinAirnetsUnits: Thunk<ISitesModel, string>;
  updateSiteState: Action<ISitesModel, any>;
  pullSite: Thunk<ISitesModel, string | null>;
  getControlSitesUnits: Computed<
    ISitesModel,
    () => IUnit[] | any,
    IRootStoreModel
  >;
  getSites: Thunk<ISitesModel>;
  clearSiteFilter: Thunk<ISitesModel, string>;
  getSiteDualSetpiontRules: Thunk<ISitesModel, string>;
  setSiteStructureSample: Action<ISitesModel, ISiteStructureSample>;
  getSiteStructureSample: Thunk<ISitesModel>;
  exportSiteStructure: Thunk<ISitesModel, string>;
  importSiteStructure: Thunk<ISitesModel, { siteId: string, formData: FormData }>;
  getSiteSmartRules: Thunk<ISitesModel, string>;
}

export const sitesModel: ISitesModel = {
  allSites: {},
  warnedSites: [],
  blockedSites: [],
  sitesFlags: {},
  siteStructureSample: {headers: [], rows: []},
  initialize: action((state, payload) => {
    state.allSites = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  getSite: computed([state => state.allSites], allSites =>
    memo(id => {
      if (_.isNil(id)) {
        return undefined;
      }
      return allSites[id];
    }, 100)
  ),

  getSiteName: computed([state => state.allSites], allSites => id => {
    if (id && allSites[id]) {
      return allSites[id].name;
    } else {
      return "-";
    }
  }),
  getSiteByName: computed(state => name => {
    const foundSites = _.filter(
      _.values(state.allSites),
      (site: ISite) => site.name === name
    );
    if (foundSites.length > 0) {
      if (foundSites.length > 1) {
        return null;
      }

      return foundSites[0];
    }
    return null;
  }),
  getSiteFullAddress: computed([state => state.allSites], allSites => id => {
    const site = allSites[id];
    return makeFullAddressForSite(site);
  }),
  getSiteAlerts: computed(
    [
      state => state.allSites,
      (state, storeState) => storeState.alerts.allOpenAlerts,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.units.allUnits,
    ],
    (allSites, allOpenAlerts, allDevices, allUnits) =>
      memo((id, type) => {
        if (_.isUndefined(allSites[id])) {
          return [];
        }
        return _.filter(Object.values(allOpenAlerts), (alert: IAlert) => {
          if (alert.data.trigger.template.indexOf("UNIT_ERROR") !== -1) {
            const siteDevices = Object.values(allDevices).filter(
              (device: any) => device.site === alert.site
            );
            const siteUnits = Object.values(allUnits).filter((unit: any) =>
              _.includes(siteDevices, allDevices[unit.device])
            );
            const erroredUnit = siteUnits.filter(
              (unit: any) => unit.internalId === alert.data.eventData.unitId
            )[0];
            if (!erroredUnit) {
              return false;
            }
          }
          if (_.includes(allSites[id].alerts, alert.id)) {
            if (
              (type === "unresolved" && _.isNil(alert.clearTime)) ||
              type === "all"
            ) {
              return true;
            }
          }
          return false;
        });
      }, 100)
  ),
  getSiteById: thunk(async (actions, payload) => {
    return siteSdk.getSiteById(payload);
  }),
  approveSite: thunk(async (actions, payload) => {
    return siteSdk.approveSite(payload);
  }),
  changeSitePower: thunk(async (actions, payload, { getStoreActions }) => {
    const { siteId, state } = payload;
    return siteSdk.changeSitePower(siteId, state).then(() => {
      const storeActions: any = getStoreActions();
      storeActions.units.updateMultipleUnitsLocally({
        filter: "site",
        filterVal: siteId,
        key: "activeOperationStatus",
        value: state,
      });
    });
  }),
  changeSiteSetpoints: thunk(async (actions, payload, { getStoreActions }) => {
    const { siteId, setpoint } = payload;
    return siteSdk.changeSiteSetPoints(siteId, setpoint).then(() => {
      const storeActions: any = getStoreActions();
      storeActions.units.updateMultipleUnitsLocally({
        filter: "site",
        filterVal: siteId,
        key: "activeSetpoint",
        value: setpoint,
      });
    });
  }),
  getSiteMgmtData: thunk((actions, payload) => {
    return siteSdk.getSiteMgmtData(payload);
  }),
  getPowerUsage: thunk(async (actions, payload) => {
    const res = await siteSdk.getPowerUsage2(payload.siteId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      resolution: payload.resolution,
      distributeExcess: payload.distributeExcess,
    });
    return res;
  }),
  getPowerUsageAsync: thunk(async (actions, payload) => {
    const res = await siteSdk.getPowerUsageAsync(payload.siteId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      resolution: payload.resolution,
      distributeExcess: payload.distributeExcess,
    });
    return res;
  }),
  getPowerUsageRecord: thunk(async (actions, payload) => {
    const res = await siteSdk.getPowerUsageRecord(payload.recordId);
    return res;
  }),
  getEstimatedPowerUsage: thunk(async (actions, payload) => {
    const res = await siteSdk.getEstimatedPowerUsage(payload.siteId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      resolution: payload.resolution,
    });
    return res;
  }),
  getSiteSystems: computed(
    [
      state => state.allSites,
      (state, storeState) => storeState.systems.allSystems,
    ],
    (allSites, allSystems) => id => {
      if (!allSites[id]) {
        return [];
      }
      const res = _.filter(Object.values(allSystems), (system: any) => {
        return system?.site === id;
      });
      return res;
    }
  ),

  getSiteFlags: thunk(async actions => {
    const flags = await commercialSdk.getSiteFlags();
    actions.setSiteFlags(flags);
    return flags;
  }),
  setSiteFlags: action((state, payload) => {
    Object.keys(payload).forEach(key => {
      let { hasAnomalies, hasOperationalAnomalies } = payload[key];
      payload[key].hasAnomalies = payload[key].hasOperationalAnomalies =
        hasAnomalies || hasOperationalAnomalies;
    });
    state.sitesFlags = payload;
  }),
  fetchSiteSystems: thunk((actions, payload) => {
    return siteSdk.getSystems(payload);
  }),
  getSitePowerMeters: thunk((actions, payload) => {
    return siteSdk.getSitePowerMeters(payload.id, payload.type);
  }),
  getDuplicateDaikinAirnetsUnits: thunk((actions, payload) => {
    return siteSdk.getDuplicateDaikinAirnetsUnits(payload);
  }),
  getMissingDaikinAirnetsUnits: thunk((actions, payload) => {
    return siteSdk.getMissingDaikinAirnetsUnits(payload);
  }),
  pullSite: thunk(async (actions, payload) => {
    if (!payload) {
      return;
    }
    return siteSdk.getSiteById(payload).then((resp: any) => {
      actions.updateSiteState(resp);
    });
  }),
  updateSiteState: action((state, payload) => {
    state.allSites = {
      ...state.allSites,
      [payload.id]: { ...state.allSites[payload.id], ...payload },
    };
  }),
  getControlSitesUnits: computed(
    [
      state => state.allSites,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.selections.selections,
      (state, storeState) => storeState.sites.sitesFlags,
    ],
    (allSites, allUnits, selections, sitesFlags) => () => {
      const { customerId, siteId } = selections;
      if (!customerId || (siteId && !allSites[siteId])) {
        return [];
      }

      const res = _.filter(Object.values(allUnits), (unit: IUnit) => {
        return (
          customerId === unit.customer &&
          unit.isVisible &&
          (siteId
            ? unit.site === siteId
            : unit.site && !!allSites[unit.site]) &&
          unit.type === 1 &&
          (unit.site
            ? sitesFlags[unit.site] && !!sitesFlags[unit.site]?.hasDashboard
            : true)
        );
      });
      return res;
    }
  ),
  getSites: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    const sites = await sdkSite.getSites();
    actions.initialize(sites);
    return sites;
  }),

  clearSiteFilter: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.clearSiteFilter(payload);
  }),

  getSiteDualSetpiontRules: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSiteDualSetpiontRules(payload);
  }),
  setSiteStructureSample: action((state, payload) => {
    state.siteStructureSample = payload;
  }),
  getSiteStructureSample: thunk(async(actions, payload, { injections }) => {
    const { sdkService } = injections;
    const result = await sdkService.getSiteStructureSample();

    if(result) {
      actions.setSiteStructureSample(result);
    }
  }),
  exportSiteStructure: thunk((actions, siteId, { injections }) => {
    const { sdkService } = injections;
    return sdkService.exportSiteStructure(siteId);
  }),
  importSiteStructure: thunk((actions, payload, { injections }) => {
    const { sdkService } = injections;
    return sdkService.importSiteStructure(payload.siteId, payload.formData);
  }),
  getSiteSmartRules: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSiteSmartRules(payload);
  }),
};
