import { Button, Popover, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { t } from "ttag";
import urlFlagMap from "../../constants/urlToFlagMapping";
import { Arrow as SvgArrow, Calendar } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IDateRange } from "../../models/Selections";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";
import ExternalsMenu from "../Menu/ExternalsMenu";
import UnitsMenu from "../Menu/UnitsMenu";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import useStyles from "./SelectionsMenu.style";
import { truncateText } from "@components/TruncatedText";
import BacnetDevicesMenu from "@components/Menu/BacnetDevicesMenu";
export interface ISelectionsMenuProps {
  onCustomerSelect?: (id: string) => void;
  onSiteSelect?: (id: string) => void;
  onSystemSelect?: (id: string, changePage?: boolean) => void;
  onUnitSelect?: (id: string, changePage?: boolean) => void;
  onDateSelect?: (clearFilter: boolean) => void;
  hideCustomerSelection?: boolean;
  hideSiteSelection?: boolean;
  hideSystemSelection?: boolean;
  hideUnitSelection?: boolean;
  showDateRangePicker?: boolean;
  showOneDatePicker?: boolean;
  disableOneDatePicker?: boolean;
  hideAllOptionsFromDropdown?: string[];
  customGeneralNames?: any;
  showSensors?: boolean;
  showBacnetDevices?:boolean;
  minDate?: any;
  countControlUnits?: boolean;
  screenTitle?: string;
  hideOutdoor?: boolean;
  hideBsBox?: boolean;
  hideIndoor?: boolean;
  hideOther?: boolean;
  hideIfCustomerNotMulti?: boolean;
  applySiteTypeFiltering?: boolean;
  passSelectedDate?: any;
  hideControl?: boolean;
  noRangeLimit?: boolean;
  allowUnassignedControls?: boolean;
  pageCustomSelector?: any;
  textAllCustomers?: boolean;
  customDateRangePickerComponent?: any;
}

const generalNamesConst = {
  customer: t`All Customers`,
  selectCustomer: t`Select Customer`,
  site: t`All Sites`,
  system: t`All Systems`,
  unit: t`All Units`
};

const SelectionsMenu = ({
  onCustomerSelect,
  onSiteSelect,
  onSystemSelect,
  onUnitSelect,
  onDateSelect,
  hideCustomerSelection = false,
  hideSiteSelection = false,
  hideSystemSelection = false,
  hideUnitSelection = false,
  showDateRangePicker = false,
  showOneDatePicker = false,
  disableOneDatePicker = false,
  hideAllOptionsFromDropdown = [],
  textAllCustomers = false,
  customGeneralNames = {},
  showSensors = false,
  showBacnetDevices = false,
  minDate = new Date("1900-01-01"),
  countControlUnits = false,
  screenTitle = "",
  hideOutdoor = false,
  hideBsBox = false,
  hideIndoor = false, // it means hide service
  hideIfCustomerNotMulti = false,
  applySiteTypeFiltering = false,
  passSelectedDate,
  hideControl = false,
  noRangeLimit = false,
  allowUnassignedControls = false,
  pageCustomSelector,
  customDateRangePickerComponent = null
}: ISelectionsMenuProps) => {

  const c = useStyles();
  const selections = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const customers = useStoreState((s) => s.selections.getCustomersBySelection);
  const userPref = useStoreState((s) => s.users.userPreferences);
  const updateUserPreferences = useStoreActions(a => a.users.updateUserPreferences)
  const sites = useStoreState((s) => s.selections.getSitesBySelection);
  const systems = useStoreState((s) => s.selections.getSystemsBySelection);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const countUnitsFor = useStoreState((s) => s.units.countUnitsFor);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const getUnit = useStoreState((s) => s.units.getUnit);

  const getPowerMeterName = useStoreState((s) => s.powerMeters.getPowerMeterName);
  const getBacnetDeviceName = useStoreState((s) => s.bacnetDevices.getBacnetDeviceName);
  const getSensorName = useStoreState((s) => s.sensors.getSensorName);
  const getSensorGroupName = useStoreState((s) => s.sensors.getSensorGroupName);
  const hasExternals = useStoreState((s) => s.selections.getExternalsBySelection);

  const [anchorEl, setAnchorEl] = React.useState({ unitsMenu: null, dateRangePicker: null, externalsMenu: null, bacnetsMenu: null });

  const unitsMenuOpen = Boolean(anchorEl.unitsMenu);
  const dateRangePickerOpen = Boolean(anchorEl.dateRangePicker);
  const externalsMenuOpen = Boolean(anchorEl.externalsMenu);
  const selectedUnitName = getUnitName(selections.unitId, true);
  const selectedSensorName = selections.sensorId === "all" ? t`All Sensors` : getSensorName(selections.sensorId);
  const selectedSensorGroupName = selections.sensorGroupId === "all" ? t`All Sensor Groups` : getSensorGroupName(selections.sensorGroupId);
  const selectedPowerMeterName = selections.powerId === "all" ? t`All Power Meters` : getPowerMeterName(selections.powerId);
  const selectedBacnetDeviceName = selections.bacnetDeviceId === "all" ? t`All BACnet devices`: getBacnetDeviceName(selections.bacnetDeviceId)
  const location = useLocation();


  /**
   * This function sets the selected customer based on a prop saved in the UserPreferences.
   * @returns 
   */
  const initSelectedCustomer = () => {
    //If userPref is undefined. return.
    if (!userPref) {
      return
    }

    //If customers list is undefined or empty. return.
    if (!customers || !customers.length) {
      return
    }

    //Get the selectedPropCustomer from the userPref.
    const { selectedProCustomer } = userPref

    //Check if the selectedProCustomer is in the list of customers for this user.
    //If it is - set it.
    //If it is not - set the first customer in the list.
    if (selectedProCustomer) {
      const customer = customers.find(({ id }) => selectedProCustomer === id) ?? customers[0];
      updateSelections({ type: "customer", data: customer.id });
    } else {
      updateSelections({ type: "customer", data: customers[0].id });
    }
  }

  useEffect(() => {
    if (!selections.customerId) {
      initSelectedCustomer()
    }
  }, []);


  useEffect(() => {
    const { systemId, unitId, siteId } = selections;

    if (siteId) {
      const pageFlag = urlFlagMap[screenTitle];
      if (pageFlag) {
        if (sitesFlags[siteId] && !sitesFlags[siteId][pageFlag]) {
          updateSelections({ type: "site", data: null });
          return;
        }
      }
    }

    if (systemId === "externals" && !showSensors) {
      updateSelections({ type: "system", data: null });
      return;
    }

    const unit = getUnit(unitId);
    if (!unit?.isVisible) {
      updateSelections({ type: "unit", data: null });
      return;
    }
    if (unit && hideOutdoor && unit?.type === 2) {
      updateSelections({ type: "unit", data: null });
      return;
    }

    if (unit && hideBsBox && unit?.type === 4) {
      updateSelections({ type: "unit", data: null });
      return;
    }
    if (unit && hideIndoor && unit?.type === 3) {
      updateSelections({ type: "unit", data: null });
      return;
    }
    if (unit && unit.serviceUnits?.length && unit?.type === 1 && !hideIndoor) {
      updateSelections({ type: "unit", data: unit.serviceUnits[0] });
      return;
    }
  }, [selections.siteId, location]);

  // Unit in popover selected
  const setUnit = (unitId: string | null, changePage = true) => {
    if (onUnitSelect) {
      onUnitSelect(unitId as any);
      handleClose();
      return;
    }
    updateSelections({ type: "unit", data: unitId });
    handleClose();
  };

  // Selections state management
  const setCustomer = (id: string) => {
    updateSelections({ type: "customer", data: id });
    if (!!id) {
      updateUserPreferences({ selectedProCustomer: id })
    }
    onCustomerSelect && onCustomerSelect(id);
  };

  const setSite = (id: string) => {
    updateSelections({ type: "site", data: id });
    onSiteSelect && onSiteSelect(id);
  };
  const setDevice = (id: string) => {
    updateSelections({ type: "device", data: id });
  };

  const setSystem = (id: string, changePage = true) => {
    updateSelections({ type: "system", data: id });
    onSystemSelect && onSystemSelect(id);
  };

  const handleNewDateRange = (range: IDateRange | null) => {
    // If null - user chose Cancel, so we don't change current selection
    if (range) {
      updateSelections({ type: "time", data: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null, externalsMenu: null, bacnetsMenu: null });
  };
  const handleDateChange = (date: any | null) => {
    // If null - user chose Cancel, so we don't change current selection
    onDateSelect && onDateSelect(false);
    if (date) {
      const now = new Date();
      let endDate;
      if (new Date(new Date(date?.getTime()).setHours(23, 59, 59)) > now) {
        endDate = now;
      } else {
        endDate = new Date(new Date(date?.getTime()).setHours(23, 59, 59));
      }
      const range = { startDate: new Date(new Date(date.getTime()).setHours(0, 0, 0)), endDate };
      passSelectedDate && passSelectedDate(range);
      updateSelections({ type: "time", data: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null, externalsMenu: null, bacnetsMenu: null });
  };

  const generalNames = { ...generalNamesConst, ...customGeneralNames };

  const getOptions = useCallback((itemType: "customer" | "site" | "system", items: any) => {
    const options = items.reduce((arr: any, item: any) => {
      if (itemType === "system" && (urlFlagMap[screenTitle] && sitesFlags[item?.site] && !sitesFlags[item?.site][urlFlagMap[screenTitle]])
      ) {
        return arr;
      }

      if (itemType === "site" && (urlFlagMap[screenTitle] && sitesFlags[item?.id] && !sitesFlags[item.id][urlFlagMap[screenTitle]])) {
        return arr;
      }

      const itemName = itemType === 'customer' ?
        (userPref?.nicknames && userPref?.nicknames[item.id]) || item.name :
        item.name

      arr.push({
        name: `${truncateText(itemName, 40)} (${countUnitsFor(itemType, item.id, countControlUnits)})`,
        value: item.id,
        key: item.id,
        type: item.type,
        device: item.device || ""
      });
      if (itemType === 'customer') {
        return arr.sort((a: any, b: any) => a.name.localeCompare(b.name));
      }
      return arr;
    }, []);

    if (itemType === "system" && showSensors && hasExternals) { // need to be there only if site is selected or its fine?
      const name = t`External Sources`;
      options.push({
        name,
        value: "externals",
        key: "externals",
        type: "externals",
        device: ""
      });
    }

    return _.isEmpty(options) && itemType !== "site"
      ? [{ name: `No ${itemType}s`, value: "", key: itemType, type: 0 }]
      : options;
  }, [countControlUnits, countUnitsFor, hasExternals, screenTitle, showSensors, sitesFlags, userPref]);

  const setDatePickerDialogReference = (ref: any) => {
  };
  const printDateRange = (range: IDateRange | null) => {
    if (_.isNil(range)) {
      return "Select date range";
    }
    return `${moment(range.startDate).format("ll")} - ${moment(range.endDate).format("ll")}`;
  };

  // Click handler for dropdown button which assigns correct anchor and opens Popover
  type IAnchor = "unitsMenu" | "dateRangePicker" | "externalsMenu" | "bacnetsMenu";
  const handleClick = useCallback(
    (anchorName: IAnchor) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl({ ...anchorEl, [anchorName]: event.currentTarget });
    },
    []
  );
  const handleClose = () => {
    setAnchorEl({ unitsMenu: null, dateRangePicker: null, externalsMenu: null, bacnetsMenu: null });
  };

  const customersOptions = useMemo(() => {
    if (!hideCustomerSelection) {
      return getOptions("customer", customers);
    }
  }, [hideCustomerSelection, customers, getOptions]);

  const sitesOptions = useMemo(() => {
    if (!hideSiteSelection) {
      return getOptions("site", sites);
    }
  }, [hideSiteSelection, sites, getOptions]);

  const systemsOptions = useMemo(() => {
    if (!hideSystemSelection) {
      return getOptions("system", systems);
    }
  }, [hideSystemSelection, systems, getOptions]);

  useEffect(() => {
    if (sitesOptions?.length === 1 && selections.siteId !== sitesOptions[0]?.value) {
      setSite(sitesOptions[0].value);
    }
  }, []);

  return (
    <div className={c.selectionsContainer}>
      {(hideCustomerSelection || (hideIfCustomerNotMulti && customersOptions?.length === 1)) ?
        <></> :
        <MenuDropDown
          onChange={setCustomer}
          value={selections.customerId || ""}
          options={[
            ...(
              customersOptions?.length < 2 ?
                [] :
                [{
                  name: generalNames[textAllCustomers ? "customer" : 'selectCustomer'] + ` (${customersOptions.length})`,
                  value: "",
                  key: "customer",
                  type: 0
                }]
            ),
            ...customersOptions]}
          hideArrow={customersOptions.length === 1}
        />
      }
      {!hideSiteSelection && (
        <MenuDropDown
          onChange={setSite}
          hideArrow={sitesOptions?.length === 1}
          value={selections.siteId || ""}
          disableNotAllowed={true}
          options={[
            ...(
              (hideAllOptionsFromDropdown.includes("site")) ?
                [] :
                [{
                  name: generalNames["site"] + ` (${sitesOptions.length})`,
                  value: "",
                  key: "site",
                  type: 0
                }]
            ),
            ...sitesOptions]}
        />
      )}
      {!hideSystemSelection && (
        <MenuDropDown
          onChange={setSystem}
          setDevice={setDevice}
          value={
            selections.systemId || ""
          }
          options={[
            ...(
              (hideAllOptionsFromDropdown.includes("system")) ?
                [] :
                [{
                  name: systemsOptions.length ? generalNames["system"] : t`No systems`,
                  value: "",
                  key: "system",
                  type: 0
                }]
            ),
            ...systemsOptions
          ]}
        />
      )}
      {(!hideUnitSelection && selections.systemId !== "externals") && (
        <React.Fragment>
          <Button
            style={{ width: "20%" }}
            disableRipple
            className={clsx(c.selectUnitButton, { [c.btnDisabled]: false })}
            classes={{
              label: c.selectUnitButton__text,
              endIcon: c.Arrow
            }}
            size="large"
            onClick={handleClick("unitsMenu")}
            endIcon={<SvgArrow />}
          >
            <Typography className={c.selectedUnitStyle}>
              {selections.unitId ? selectedUnitName : generalNames.unit}
            </Typography>
          </Button>
          <Popover
            open={unitsMenuOpen}
            anchorEl={anchorEl.unitsMenu}
            onClose={handleClose}
            classes={{ paper: c.paperBg }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <UnitsMenu
              hideOutdoor={hideOutdoor}
              hideBsBox={hideBsBox}
              hideIndoor={hideIndoor}
              hideControl={hideControl}
              hideOther={true}
              hideSystemSelection={hideSystemSelection}
              onUnitSelect={setUnit}
              screenTitle={screenTitle}
              generalNaming={generalNames.unit}
              showGeneralOption={hideAllOptionsFromDropdown.indexOf("unit") > -1}
              applySiteTypeFiltering={applySiteTypeFiltering}
              allowUnassignedControls={allowUnassignedControls}
            />
          </Popover>
        </React.Fragment>
      )}
      {showSensors && selections.systemId === "externals" && (
        <React.Fragment>
          <Button
            style={{ width: "20%" }}
            disableRipple
            className={clsx(c.selectUnitButton)}
            classes={{
              label: c.selectUnitButton__text
            }}
            size="large"
            onClick={handleClick("externalsMenu")}
            endIcon={<SvgArrow />}
          >
            <Typography className={c.selectedUnitStyle}>
              {selections.sensorId ? selectedSensorName : selections.powerId ? selectedPowerMeterName : selections.sensorGroupId ? selectedSensorGroupName : t`Select External Source`}
            </Typography>
          </Button>
          <Popover
            open={externalsMenuOpen}
            anchorEl={anchorEl.externalsMenu}
            onClose={handleClose}
            classes={{ paper: c.paperBg }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <ExternalsMenu handleClose={handleClose} />
          </Popover>
        </React.Fragment>
      )}
      {showBacnetDevices && (
        <React.Fragment>
          <Button
            style={{ width: "20%" }}
            disableRipple
            className={clsx(c.selectUnitButton)}
            classes={{
              label: c.selectUnitButton__text
            }}
            size="large"
            onClick={handleClick("externalsMenu")}
            endIcon={<SvgArrow />}
          >
            <Typography className={c.selectedUnitStyle}>
              {selections.bacnetDeviceId ? selectedBacnetDeviceName :  t`Select BACnet device`}
            </Typography>
          </Button>
          <Popover
            open={externalsMenuOpen}
            anchorEl={anchorEl.externalsMenu}
            onClose={handleClose}
            classes={{ paper: c.paperBg }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <BacnetDevicesMenu handleClose={handleClose} />
          </Popover>
        </React.Fragment>
      )}
      {showOneDatePicker && (
        <React.Fragment>
          <Button
            disableRipple
            disabled={disableOneDatePicker}
            className={c.selectDateRangeButton}
            classes={{
              label: c.selectDateRangeButton__text,
              iconSizeLarge: c.selectDateRangeButton__icon,
              endIcon: c.Arrow
            }}
            size="large"
            onMouseUp={handleClick("dateRangePicker")}
            endIcon={<SvgArrow />}
            startIcon={<Calendar style={{ marginLeft: "4px" }} />}
          >
            {`${moment(selections.dateRange?.endDate).format("ll")}`}
          </Button>

          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <DatePicker
              disableToolbar
              variant="static"
              value={selections.dateRange?.endDate}
              onChange={handleDateChange}
              className={c.datePicker}
              autoOk
              maxDate={new Date()}
              minDate={minDate}
              ref={(r) => setDatePickerDialogReference(r)}
            />
          </Popover>
        </React.Fragment>
      )}
      {showDateRangePicker &&
        (
          customDateRangePickerComponent === null ?
            <React.Fragment>
              <Button
                disableRipple
                className={c.selectDateRangeButton}
                classes={{
                  label: c.selectDateRangeButton__text,
                  iconSizeLarge: c.selectDateRangeButton__icon,
                  endIcon: c.Arrow
                }}
                size="large"
                onMouseUp={handleClick("dateRangePicker")}
                endIcon={<SvgArrow />}
                startIcon={<Calendar style={{ marginLeft: "4px" }} />}
              >
                <Typography className={c.selectDateRangeButton__text}>
                  {printDateRange(selections.dateRange)}
                </Typography>
              </Button>

              <Popover
                open={dateRangePickerOpen}
                anchorEl={anchorEl.dateRangePicker}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: -10,
                  horizontal: "left"
                }}
              >
                <CoolDateRangePicker
                  handleSubmit={handleNewDateRange}
                  initialRange={selections.dateRange}
                  minDate={minDate}
                  noRangeLimit={noRangeLimit}
                />
              </Popover>
            </React.Fragment> :
            customDateRangePickerComponent

        )}
      {pageCustomSelector}
    </div>
  );
};

export default memo(SelectionsMenu);
