import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";

import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./BacnetDeviceList.style";
import { IBacnetDevice } from "@models/BacnetDevices";
import BacnetDeviceForm from "./BacnetDeviceForm";
import BacnetDeviceTableRow from "./BacnetDeviceTableRow";

const BacnetDeviceList: React.FC = (props: any) => {
  const classes = useStyles();

  const selections = useStoreState((state) => state.selections.selections);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allDevices = useStoreState((state)=>state.devices.allDevices);

  const {
    fetchSiteBacnetDevices,
    updateBacnetDevice
  
  } = useStoreActions((action) => action.bacnetDevices);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [currentEntity, setCurrentEntity] = useState<IBacnetDevice|null>(null);
  const [bacnetDevices, setBacnetDevices] = useState<Record<string,IBacnetDevice>>({})

  const sortedBacnetDevices = orderBy(Object.values(bacnetDevices), [(bacnetDevice: IBacnetDevice) => bacnetDevice.name?.toLowerCase()], ["asc"]);
  const { siteId } = selections;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canUpdateBacnetDevices } = permissions || {};

  useEffect(() => {
    if (!siteId) {
      return;
    }

    fetchSiteBacnetDevices(siteId)
      .then((res: any) => setBacnetDevices(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [siteId]);

  const updateExistingBacnetDevice = (data: any, bacnetDeviceId: string) => {
    return updateBacnetDevice({ id: bacnetDeviceId, data })
      .then((resp: any) => setBacnetDevices({ ...bacnetDevices, [bacnetDeviceId]: { ...bacnetDevices[bacnetDeviceId], ...resp } }))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const closeDialog = () => {
    setOpenForm(false);
    setCurrentEntity(null);
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          screenTitle="bacnetDevices"
        />
        {!siteId ? <FilterRequire type={t`site`} /> :
          <>
            <Paper elevation={0} className={classes.paperTableContainer}>
            
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                    <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Host device`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Identifier`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Name`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`Description`}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableHeadCell }}
                        align="left"
                      >{t`EDIT`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedBacnetDevices.map((bacnetDevice) => {
                      const { canUpdate } = bacnetDevice?.permissions || {};
                      const rowProps = {
                        bacnetDevice: {...bacnetDevice, serial: allDevices[bacnetDevice.device]?.serial},
                        canUpdate,
                        setOpenForm,
                        setCurrentEntity,
                        allDevices
                      }
                      return (
                        <BacnetDeviceTableRow key={bacnetDevice.id} {...rowProps} />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>}
          {(openForm && currentEntity) &&
          <BacnetDeviceForm
            entity={currentEntity}  
            onClose={closeDialog}
            createEntity={undefined}
            updateEntity={updateExistingBacnetDevice}
            deleteEntity={undefined}
            canEdit={canUpdateBacnetDevices}
          />
        }
      </div>
    </div>
  );
};

export default BacnetDeviceList;
