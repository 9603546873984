import Grid from "@material-ui/core/Grid";
import { System as sdkSystem } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ExportUtils from "../../utils/ExportUtils";
import { BacnetServiceParam } from "@models/BacnetDevices";
import UnitStatsSlider from "@components/UnitStats/UnitStatsSlider";
import UnitStatsTable from "@components/UnitStats/UnitStatsTable";
import UnitStatsGraph from "@components/UnitStats/UnitStatsGraph";
import ErrorBox from "@components/WarningBox/ErrorBox";

const MAX_ALLOWED_STATS_SELECTIONS = 6,
  MAX_ALLOWED_GAP_PRO = 1000 * 60 * 20, // (20 mins)
  AUTOUPDATE_INTERVAL = 1000 * 60, // one minute
  STATIC_CODES = {
    ON_OFF_CODE: "48",
    ROOM_TEMP_CODE: "49",
    SETPOINT_CODE: "50",
    MODE_CODE: "51",
    FAN_CODE: "52",
    OUTDOOR_TEMP: "55",
    SITE_TEMP: "57"
  },
  paramsColorsSet: any = ["#7a6095", "#35a8e0", "#00b259", "#ef3b2f", "#f8b133", "#7f7182"];

const BacnetDeviceStats: React.FC<any> = ({
  unit,
  setSelectedTime,
  setReFetchUnit,
  isLoading,
  setIsLoading,
  selectedTime: { selectedStartTime = null, selectedEndTime = null },
  minDate
}) => {
  const location: any = useLocation();
  const getBacnetDeviceStats = useStoreActions((a)=> a.bacnetDevices.getBacnetDeviceStats)
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setStatsUpdate = useStoreActions((a) => a.setStatsUpdate);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale || 0);
  const userMeasurementUnits = useStoreState((s) => s.users.me.measurementUnits || 1);
  const types = useStoreState((s) => s.types || {});
  const unitTypesMirrror = useStoreState((s) => s.unitTypesMirrror);
  const [serviceParams, setServiceParams] = useState<Record<string,BacnetServiceParam>|null>(null)
  const selections = useStoreState((state) => state.selections.selections);
  const displayFlagsMap = useStoreState((state) => state.users.displayFlagsMap);
  const { customerId } = selections;
  const displayFlags = displayFlagsMap[customerId || ""] || {};
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const enums = useStoreState((state) => state.serviceParamTypes);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const updateUserPreferences = useStoreActions((actions) => actions.users.updateUserPreferences);
  const {
    getParamsForBacnetDeviceById
  } = useStoreActions((action) => action.bacnetDevices);

  const [isSystemLoading, setIsSystemLoading] = useState(false);
  const [isUnitLoading, setIsUnitLoading] = useState(false);
  const [colorSet, setColorSet] = useState<any>([...paramsColorsSet]);
  const [paramsColors, setParamsColors] = useState<any>(new Map());
  const [graphsData, setGraphsData] = useState<any>([]);
  const [autoupdate, setAutoupdate] = useState(false);
  const [siteTempIsLoading, setSiteTempIsLoading] = useState<boolean>(true);
  const [paramsTable, setParamsTable] = useState<any>({});
  const [statsResults, setStatsResults] = useState<any>(null);
  const defaultDateNow = new Date().getTime();
  const defaultDatePast = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
  const [callTimer, setCallTimer] = useState<any>(null);
  const getSite = useStoreState((s) => s.sites.getSite);
  const [measuredCodes, setMeasuredCodes] = useState<any[]>([]);
  const [zoomTime, setZoomTime] = useState<any>(null);
  const [externalsData, setExternalsData] = useState<any>({ powerMeters: {}, sensors: {} });
  const [counter, setCounter] = useState<number>(0);
  const [showAutoUpdateWarning, handleShowAutoUpdateWarning] = useState<boolean>(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState<boolean>(false);

  const allSystems = useStoreState((s) => s.systems.allSystems);
  const getUnitName = useStoreState((u) => u.bacnetDevices.getBacnetDeviceName);
  const getTemperatureScaleDisplayPlainText = useStoreState(
    (s) => s.users.getTemperatureScaleDisplayPlainText
  );
  const getUnitTypeByTypes = useStoreState((u) => u.units.getUnitTypeByTypes);
  const setParamValue = useStoreActions((a) => a.units.setParamValue);

  const system = unit.system && allSystems[unit.system];
  const brandName = "BACNET";

  const unitId = unit.id;
  const unitType = 'any';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  const selectedSiteId = unit?.site;
  const timezone = getSite(selectedSiteId)?.timezone || moment.tz.guess();
  const storedRuleId = localStorage.getItem("ruleId");

  // fetch and prepare serviceParams
  useEffect(() => {
    getParamsForBacnetDeviceById(unit.id).then(
      (response:any) => {
        setServiceParams(response)
      }
  )
  },[]);

  const resetParams = (code: any, unitId: string, value: any) => {
    setParamValue({
      id: unitId, serviceParamCode: code, value: +value
    }).then(() => {
      paramsTable[code].value = +value;
      setParamsTable({ ...paramsTable });
    })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const deleteParam = useCallback((code: any) => {
    showHideParam(code, "unit");
    delete paramsTable[code];
    setParamsTable({ ...paramsTable });
  }, [paramsTable]);

  const showHideAllParams = useCallback((isChecked: boolean, type: any) => {
    if (type === "custom") {
      const unitParams = { ...paramsTable };
      Object.keys(unitParams).forEach((key: any) => {
        const obj = unitParams[key];
        if (!obj || !obj?.isCustomParam) {
          return;
        }
        obj.isShowing = isChecked;
        if (!isChecked) {
          obj.isChecked = false;
        }
      });
      setParamsTable(unitParams);

    } else if (type === "unit") {
      const unitParams = { ...paramsTable };
      Object.keys(unitParams).forEach((key: any) => {
        const obj = unitParams[key];
        if (!obj || obj?.isCustomParam) {
          return;
        }
        obj.isShowing = isChecked;
        if (!isChecked) {
          obj.isChecked = false;
        }
      });
      setParamsTable(unitParams);
    }

    const rows = type === "unit" ? { ...paramsTable } : {};

    const usedColors = Object.values(Object.fromEntries(paramsColors));
    const unUsedColors = paramsColorsSet.filter((color: any) => usedColors.indexOf(color) === -1);
    Array.from(paramsColors, ([id]) => {
      if (rows[id]) {
        if (!rows[id].isChecked) {
          paramsColors.delete(id);
        }
      }
    });

    setParamsColors(paramsColors);
    setColorSet(unUsedColors);
  }, [colorSet, paramsColors, paramsTable]);

  const showHideParam = useCallback((code: any, type: string) => {
    if (type === "unit") {
      const oldVal = !!paramsTable[code].isShowing;
      setParamsTable({ ...paramsTable, [code]: { ...paramsTable[code], isShowing: !oldVal, isChecked: false } });
    }

    if (paramsColors.has(code)) {
      setColorSet([...colorSet, paramsColors.get(code)]);
      paramsColors.delete(code);
      setParamsColors(paramsColors);
    }
  }, [colorSet, paramsColors, paramsTable]);

  const prepareUserPrefData = () => {
    if (_.isEmpty(paramsTable)) {
      return;
    }
    const showingParams = Object.values(paramsTable).filter((row: any) => row?.isShowing);

    const unitsData = showingParams.map((row: any) => [row.code, true]);

    let data = { [unitType]: unitsData };
    let userPrefObj: any = userPreferences?.serviceRecentParamsO;
    if (userPrefObj) {
      userPrefObj[brandName] = {
        ...userPrefObj[brandName],
        ...data
      };
    }
    else {
      userPrefObj = {
        [brandName]: { ...data }
      };
    }

    let userSelectedPrefObj: any = userPreferences?.serviceSelectedStats;
    let savedData: any = {};

    if (!_.isEmpty(paramsTable)) {
      const checkedParams = Object.values(paramsTable).filter((row: any) => row?.isChecked);
      let paramsData: any = {};
      checkedParams.map((row: any) => paramsData[row.code] = true);
      savedData[unitType] = paramsData;
    }
    if (userSelectedPrefObj) {
      userSelectedPrefObj[brandName] = {
        ...userSelectedPrefObj[brandName],
        ...savedData
      };
    }
    else {
      userSelectedPrefObj = {
        [brandName]: { ...savedData }
      };
    }

    updateUserPreferences({ serviceRecentParamsO: userPrefObj, serviceSelectedStats: userSelectedPrefObj });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    prepareUserPrefData();
  }, [paramsTable, measuredCodes]);

  

  useEffect(() => {


    const storedStringTime = localStorage.getItem("time");
    const storedTime = storedStringTime ? parseInt(storedStringTime, 10) : null;
    const storedUnitId = localStorage.getItem("unitId");
    if (!location) {
      return;
    }
    const { state } = location;

    if ((!!state && state.unitId === unit.id && !!state?.time) || (!!storedUnitId && storedUnitId === unit.id && !!storedTime)) {
      const time = state?.time || storedTime;
      const diff = moment.now() - time;
      const startTime = time - 43200 * 1000;
      const endTime = diff < 43200000 ? moment.now() : (time + 43200000);
      if (!isToday(new Date(moment(endTime).tz(timezone).format("llll"))) || (endTime - startTime > 86400000)) {
        setDisableUpdateBtn(true);
      }
      setSelectedTime({ selectedStartTime: startTime, selectedEndTime: endTime });
      localStorage.removeItem("time");
      localStorage.removeItem("unitId");
    }
    else {
      if (!selectedStartTime || !selectedEndTime) {
        setSelectedTime({ selectedStartTime: defaultDatePast, selectedEndTime: defaultDateNow });
      }
    }
  }, [unit.id]);

  const updateParamRow = useCallback((code: any, type: string) => {
    const useThese: any = type === "param" ? { data: paramsTable, update: setParamsTable } : {};
    const { data, update } = useThese;
    const oldVal = data[code].isChecked;
    const colorSetTemp = colorSet;

    if (!oldVal && paramsColors.size >= MAX_ALLOWED_STATS_SELECTIONS) {
      return;
    }

    if (!oldVal) {
      const newColor = colorSetTemp.pop();
      paramsColors.set(code, newColor);
    } else {
      colorSetTemp.push(paramsColors.get(code));
      paramsColors.delete(code);
    }

    data[code] = { ...data[code], isChecked: !oldVal };

    update({ ...data });
    setParamsColors(paramsColors);
    setColorSet(colorSetTemp);

  }, [paramsTable, colorSet, paramsColors, userPreferences]);

  const prepareTableData = async (data: any, currentObj: any, serviceParams: any) => {

    let selectedStats: any = userPreferences?.serviceSelectedStats;
    let prevPreferdStats: any = {};
    const newSelected: any = new Map();
    const colorSetTemp = [...paramsColorsSet];
    const tableRows: any = {};

    if (selectedStats) {
      prevPreferdStats = selectedStats[brandName][unitType];
    }

    const unitSupportedParams = !data ? null : Object.keys(data);
    if (data && !_.isEmpty(serviceParams) && unitSupportedParams && currentObj) {

      localStorage.removeItem("unitId");
      localStorage.removeItem("trap");

      let memo: any = null;
      let recentParams: any = null;
      const userPrefObj: any = userPreferences?.serviceRecentParamsO;
      const measuredArr: any = [];
      const unMeasuredArr: any = [];

      if (userPrefObj && userPrefObj[brandName] && userPrefObj[brandName][unitType] && brandName && unitType && unitId) {
        recentParams = new Map(userPrefObj[brandName][unitType]);
        memo = Object.fromEntries(recentParams);
      }

      unitSupportedParams && !_.isEmpty(unitSupportedParams) && unitSupportedParams.forEach((code: any) => {
        const {
          min: minRanges = 0,
          max: maxRanges = 0
        } = data[code];
        const value = currentObj.jdata[code];

        const servParam = serviceParams[code];
        if (!servParam) {
          return;
        }

        const {
          showInGraph = true,
          hvac_param_name = "",
          plotable,
          data_unit_of_measurement: measurementUnit = "",
          min = 0,
          max = 0,
          title = "",
          enum: enumName = null,
          editable,
          value_type,
          techInfo,
          button,
          buttonText,
          buttonValue,
          isCalculated,
          isCustomParam = false,
          id,
          formula
        } = servParam;

        let isChecked = false;
        const isShowing = memo ? !!memo[code] : true;
        let hideInTable = false;

        if (memo && memo[code] && prevPreferdStats[code] && newSelected.size < MAX_ALLOWED_STATS_SELECTIONS) {
          isChecked = true;
          //hideInTable = true;
          newSelected.set(code, colorSetTemp.pop());
        }

        if (!showInGraph && !hideInTable) {
          tableRows[code] = null;
          return;
        }
        hideInTable = showInGraph;

        const measuredParam: boolean = servParam
          ? !_.isUndefined(plotable)
            ? plotable
            : true
          : false;

        if (measuredParam) {
          measuredArr.push(code);
        }
        else {
          unMeasuredArr.push(code);
        }

        let measurementUnits: string = measurementUnit;

        if (temperatureScale === 2 && measurementUnit === "°C") {
          measurementUnits = "°F";
        }
        if (userMeasurementUnits === 2 && measurementUnit === "kg/cm2") {
          measurementUnits = "PSI";
        }
        if (measurementUnit === "MPa" && userMeasurementUnits === 2) {
          measurementUnits = "PSI";
        }
        if (userMeasurementUnits === 1 && measurementUnit === "PSI") {
          measurementUnits = "kg/cm2";
        }

        let enumVal: any = null;

        if (enumName) {
          enumVal = enums?.[enumName]?.[value];
        }
        const slider = measuredParam ? <UnitStatsSlider data={{
          code,
          slider: {
            min,
            max,
            selectionMin: minRanges,
            selectionMax: maxRanges,
            value
          },
          value,
          name: title
        }} index={`slider${code}`} /> : <span />;

        tableRows[code] = {
          code,
          editable,
          name: title,
          value,
          measurementUnits,
          slider,
          showInGraph,
          hvac_param_name,
          plottable: measuredParam,
          isShowing,
          type: "unit",
          isChecked,
          enumVal,
          value_type,
          enum: enumName,
          techInfo,
          button,
          buttonText,
          buttonValue,
          hideInTable,
          isCalculated,
          isCustomParam,
          id,
          formula
        };
      });
    }

    setParamsTable({ ...tableRows });
    setParamsColors(newSelected);
    setColorSet(colorSetTemp);
  };

  const prepareGraphData = (statsData: any, selectedStartTime: any, selectedEndTime: any) => {
    const { calculatedMode } = types;
    const graphsTemp: any = [];
    const modePoint: any = {};

    for (let p of Object.values(calculatedMode)) {
      modePoint[p as string] = 0;
    }

    graphsTemp.push({
      timestamp: selectedStartTime,
      noData: 0.5
    });

    statsData.forEach((statPoint: any, statPointIndex: any) => {
      if (statPoint) {

        let point = { ...statPoint, noData: 0.5 };

        if (
          statPoint &&
          (statPoint["calculated_mode"] !== undefined)
        ) {
          const finalMode = statPoint["calculated_mode"];
          const modePointTemp = { ...modePoint, [calculatedMode[finalMode]]: 0.5 };
          point = { ...point, ...modePointTemp };
        }

        graphsTemp.push({
          ...point
        });

        if (
          statsData[statPointIndex + 1] &&
          statsData[statPointIndex + 1].timestamp >= statPoint.timestamp + MAX_ALLOWED_GAP_PRO
        ) {
          graphsTemp.push({
            timestamp: statPoint.timestamp + 2,
            noData: 0.5
          });
        }

      }

    });

    graphsTemp.push({
      timestamp: selectedEndTime,
      noData: 0.5
    });

    setGraphsData(graphsTemp);
  };

  const fetchData = (bacnetDeviceId: string, startTime: number, endTime: number, serviceParams: any) => {
    setIsLoading(true);
    const bacnetDeviceStatsAPI = getBacnetDeviceStats({ bacnetDeviceId, startTime, endTime });
    const APIs = [bacnetDeviceStatsAPI];

    Promise.all(APIs)
      .then((res: any) => {
        const currentobj = res[0]?.current && res[0]?.current[0];
        prepareGraphData(res[0].entries, startTime, endTime);
        prepareTableData(res[0].ranges, currentobj, serviceParams);
        setStatsResults(res[0]);
      })
      .catch(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        // remove the ruleId after the table param is ready
        localStorage.removeItem("ruleId");
        if (autoupdate) {
          if (counter === 59) {
            setAutoupdate(false);
            handleShowAutoUpdateWarning(true);
            setCounter(0);
          } else {
            setCounter(counter + 1);
          }
        }
      });
  };

  useEffect(() => {
    if (!unit?.id || !selectedStartTime || !selectedEndTime || !temperatureScale || _.isEmpty(enums) || !serviceParams) {
      return;
    }
    let startTime = selectedStartTime;
    let endTime = selectedEndTime;

    if (zoomTime) {
      startTime = zoomTime.startTime;
      endTime = zoomTime.endTime;
    }

    !autoupdate && setParamsTable({});
    fetchData(unit.id, startTime, endTime, serviceParams);

  }, [unit.id, selectedStartTime, selectedEndTime, temperatureScale, userMeasurementUnits, zoomTime, serviceParams, enums]);

  const reFetchData = useCallback(() => {
    fetchData(unit.id, selectedStartTime, selectedEndTime, serviceParams);
    setStatsUpdate(false);
  }, [selectedStartTime, selectedEndTime, serviceParams]);

  const onRefresh = useCallback(() => {
    if (!selectedStartTime) {
      return;
    }

    if (zoomTime) {
      setZoomTime(null);
    }
    onDateRangeChange({ startDate: new Date(moment(selectedStartTime).tz(timezone).format("llll")), endDate: new Date(moment().tz(timezone).format("llll")) }, true);
    setStatsUpdate(true);
  }, [selectedStartTime, zoomTime]);

  const isToday = useCallback((date: any) => {
    const today = new Date(moment().tz(timezone).format("llll"));
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }, []);

  const onDateRangeChange = useCallback(async (
    dateRange: {
      startDate?: Date | undefined;
      endDate?: Date | undefined;
    },
    skipDateFrameChecking = false, 
    timeLimit = 0) => {
        if (!_.isUndefined(dateRange.startDate) && !_.isUndefined(dateRange.endDate)) {
          if (zoomTime && !autoupdate) {
            setZoomTime(null);
          }

          const timezoneOffset = moment().tz(timezone).utcOffset() * 60 * 1000;
          const checkIsToday = isToday(dateRange.endDate);
          const allowedPastTime = new Date().getTime() - (timeLimit * 60 * 60 * 1000);
          let startDate = !!timeLimit ? dateRange.startDate.getTime() < allowedPastTime ? new Date(allowedPastTime) : dateRange.startDate : dateRange.startDate;
          const currentHourMinsArray = moment().tz(timezone).format("HH:mm").split(":");
          const startHoursArray = moment(startDate).format("HH:mm").split(":");
          const startTime = Date.UTC(startDate.getFullYear(),
            startDate.getMonth(), startDate.getDate(), +startHoursArray[0], +startHoursArray[1]) - timezoneOffset;
          const endTime = checkIsToday ? Date.UTC(dateRange.endDate.getFullYear(),
            dateRange.endDate.getMonth(), dateRange.endDate.getDate(), +currentHourMinsArray[0], +currentHourMinsArray[1], 0) - timezoneOffset :
            Date.UTC(dateRange.endDate.getFullYear(),
              dateRange.endDate.getMonth(), dateRange.endDate.getDate(), 23, 59, 59) - timezoneOffset;

          if (!isToday(new Date(moment(endTime).tz(timezone).format("llll"))) || (!skipDateFrameChecking && (endTime - startTime > 86400000))) {
            autoupdate && setAutoupdate(false);
            !disableUpdateBtn && setDisableUpdateBtn(true);
            counter && setCounter(0);
          } else {
            disableUpdateBtn && setDisableUpdateBtn(false);
          }

          setSelectedTime({ selectedStartTime: startTime, selectedEndTime: endTime });
        }
    }, [autoupdate, disableUpdateBtn, counter, zoomTime]),
    exportFile = useCallback(async (source: string, staticCodes?: number[]) => {
      const startDateTime = moment(selectedStartTime).format(dateTimeFormat);
      const endDateTime = moment(selectedEndTime).format(dateTimeFormat);
      const { unitTypes } = types;
      const fileNamePrefix = `${source.indexOf("full") !== -1 ? "all_" : "selected_"}${source.indexOf("unit") !== -1 ? "unit" : "system"}`;
      const checkedParams: any = Object.values(paramsTable).reduce((obj: any, row: any) => {
        let key = row?.code;
        if (row?.isChecked) {
          obj[key] = row?.code;
        }
        return obj;
      }, {});

      if (staticCodes?.length) {
        for (let param of staticCodes) {
          checkedParams[param] = param;
        }
      }
      if (source.indexOf("unit") !== -1) {
        setIsUnitLoading(true);
        const headerCodes: any[] = source === "full unit" ? [] : Object.values(checkedParams);
        const isReduced = false;
        let dataObj = {
          bacnetDeviceId: unitId,
          startTime: zoomTime ? zoomTime.startTime : selectedStartTime,
          endTime: zoomTime ? zoomTime.endTime : selectedEndTime,
          isReduced
        };
        getBacnetDeviceStats(source === "full unit" ? dataObj : { ...dataObj, params: headerCodes })
          .then((resp: any) => {
            let rows = resp.entries;
            let codeHeadersArray = ExportUtils.buildUniqueCodesHeader(source === "full unit" ? rows : headerCodes, source);
            let { codes, titles } = ExportUtils.getHeadersCodesAndTitles(
              codeHeadersArray,
              serviceParams,
              getTemperatureScaleDisplayPlainText()
            ),
              // insert the header in csvContent
              csvContent = "data:text/csv;charset=utf-8," + titles.join(",");
            csvContent += "\r\n";

            csvContent += ExportUtils.buildContent(
              rows,
              unit.name,
              unitTypesMirrror[unit.type] || "--",
              codes,
              timezone,
              dateTimeFormat
            );
            setIsUnitLoading(false);
            ExportUtils.downloadFile(csvContent, `${fileNamePrefix}-${unit?.name}-${startDateTime}-${endDateTime}`);
          }).catch((err: any) => {
            setIsUnitLoading(false);
            addMessage({ message: err.message });
          });
      }
      if (source.indexOf("system") !== -1 && !_.isNil(unit.system)) {
        setIsSystemLoading(true);
        const system = allSystems[unit.system];
        if (!_.isNil(system)) {
          const rows: Array<{ unitName: string; unitType: string; unitRows: any[] }> = [];
          sdkSystem.getSystemDiagData(system.id, selectedStartTime, selectedEndTime)
            .then((data: any) => {
              const allSystemUnits = source === "full system" ? { ...data.indoors, ...data.outdoors } : unit.type === unitTypes["outdoor"] ? { ...data.outdoors } : { ...data.indoors };
              const unitIds = Object.keys(allSystemUnits);

              unitIds.forEach((unitId: string) => {
                const unitName = getUnitName(unitId);
                const unitType = getUnitTypeByTypes(unitId);
                const unitTypeParsed = unitType === "service" ? "indoor" : unitType;

                rows.push({ unitName, unitType: unitTypeParsed, unitRows: allSystemUnits[unitId].entries });
              });

              let codeHeadersArray: string[] = [];
              if (source === "full system") {
                rows.forEach((row) => {
                  codeHeadersArray = _.union(
                    codeHeadersArray,
                    ExportUtils.buildUniqueCodesHeader(row.unitRows, source)
                  );
                });
              } else {
                codeHeadersArray = _.union(
                  codeHeadersArray,
                  ExportUtils.buildUniqueCodesHeader(checkedParams, source)
                );
              }

              let { codes, titles } = ExportUtils.getHeadersCodesAndTitles(
                codeHeadersArray,
                serviceParams,
                getTemperatureScaleDisplayPlainText()
              );
              let csvContent = "data:text/csv;charset=utf-8," + titles.join(",");
              csvContent += "\r\n";

              rows.forEach((row) => {

                csvContent += ExportUtils.buildContent(
                  row.unitRows,
                  row.unitName,
                  row.unitType,
                  codes,
                  timezone,
                  dateTimeFormat
                );
              });
              setIsSystemLoading(false);
              ExportUtils.downloadFile(csvContent, `${fileNamePrefix}-${system?.name}-${startDateTime}-${endDateTime}`);
            }).catch((err: any) => {
              setIsSystemLoading(false);
              addMessage({ message: err.message });
            });
        }
      }
    }, [statsResults, paramsTable]);

  const startAutoUpdate = () => {
    clearInterval(callTimer);
    setCallTimer(null);
    if (autoupdate) {
      setCallTimer(
        setInterval(() => {
          onRefresh();
        }, AUTOUPDATE_INTERVAL)
      );
    }
  };
  useEffect(() => {
    if (autoupdate) {
      startAutoUpdate();
    } else {
      clearInterval(callTimer);
      setCallTimer(null);
    }
  }, [autoupdate, selectedStartTime]);

  return (
    <Grid container spacing={0} style={{ padding: "16px 0" }}>
      <Grid item xs={5} style={{ maxHeight: "554px" }}>
        <UnitStatsTable
          deleteParam={deleteParam}
          unitType={'any'}
          brand={'BACNET'}
          paramsTable={paramsTable}
          endTime={selectedEndTime}
          isToday={isToday}
          onRefresh={onRefresh}
          isLoading={isLoading}
          updateTime={statsResults?.current[0]?.timestamp}
          autoupdate={autoupdate}
          setAutoupdate={setAutoupdate}
          updateMeasuredRows={setMeasuredCodes}
          showHideAllParams={showHideAllParams}
          showHideParam={showHideParam}
          updateParamRow={updateParamRow}
          withQuality={false}
          unit={unit.id}
          reFetchData={reFetchData}
          maxAllowedStats={MAX_ALLOWED_STATS_SELECTIONS}
          timezone={timezone}
          sensorsRows={{}}
          powerMetersRows={{}}
          resetParams={resetParams}
          disableUpdateBtn={disableUpdateBtn}
          system={system || {}}
          storedRuleId={storedRuleId}
        />
      </Grid>
      <Grid item={true} xs={7}>
        <UnitStatsGraph
          isToday={isToday}
          startTime={selectedStartTime}
          endTime={selectedEndTime}
          graphs={graphsData}
          types={types}
          onDateRangeChange={onDateRangeChange}
          onChipDelete={updateParamRow}
          onRefresh={onRefresh}
          isLoading={isLoading}
          isSystemLoading={isSystemLoading}
          isUnitLoading={isUnitLoading}
          exportFile={exportFile}
          isNotSystemUnit={_.isNil(unit.system)}
          paramsTable={paramsTable}
          paramsColors={paramsColors}
          paramsMap={serviceParams}
          staticCodes={STATIC_CODES}
          hideAlerts={true}
          alertsData={{}}
          unitType={getUnitTypeByTypes(unit.id)}
          temperatureScale={temperatureScale}
          hideSiteTemp={true}
          siteExternalTemps={{}}
          disableDatepicker={!displayFlags.enableUnitDiagnosticsDatepicker}
          disableExport={!displayFlags.enableUnitDiagnosticsExport}
          qualityData={{}}
          siteTempIsLoading={siteTempIsLoading}
          serviceParams={serviceParams}
          enums={enums}
          timezone={timezone}
          setZoomTime={setZoomTime}
          zoomTime={zoomTime}
          sensorsRows={{}}
          powerMetersRows={{}}
          externalsData={externalsData}
          minDate={minDate}
          storedRuleId={storedRuleId}
        />
      </Grid>
      {showAutoUpdateWarning && <ErrorBox
        title={t`Confirm`}
        error={"Auto Update has stopped. Do you want to proceed?"}
        onAccept={() => { handleShowAutoUpdateWarning(false); setAutoupdate(true); }}
        onClose={() => handleShowAutoUpdateWarning(false)}
        acceptTitle={t`Yes`}
        cancelTitle={t`No`}
      />
      }
    </Grid>
  );
};

export default BacnetDeviceStats;
