import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { SmartRule as SDKSmartRule } from "coolremote-sdk";
import { Autocomplete } from "@material-ui/lab";
import _, { includes, isEmpty, some } from "lodash";
import { debounce } from 'lodash';
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation, useRouteMatch } from "react-router";
import { t } from "ttag";
import { NotesAndAttachments } from "../../components/";
import AddRule from "../../components/AddRule/ReadOnlyAddRule";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SensorControl from "../../components/SensorControl/UnitControl";
import SensorInfo from "../../components/SensorInfo/UnitInfo";
import SensorStats from "../../components/SensorStats/UnitStats";
import SystemOverview from "../../components/SystemOverview/SystemOverview";
import TemperatureInfo from "../../components/TemperatureInfo/TemperatureInfo";
import UnitControl from "../../components/UnitControl/UnitControl";
import UnitInfo from "../../components/UnitInfo/UnitInfo";
import UnitStats from "../../components/UnitStats/UnitStats";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlthermaUnitSettings from "../../screens/AlthermaUnitSettings/AlthermaUnitSettings";
import { downloadAs } from "../../services/download";
import { MenuSearch as Search } from "../../svgComponents";
import AddEditRule from "../SmartRules/AddEditRule/AddEditRule";
import useStyles from "./DashboardUnit.style";
import useChurnZero from "@hooks/useChurnZero";

const defaultKeys = { modeKey: "activeOperationMode", powerKey: "activeOperationStatus", ambientTempKey: "ambientTemperature", setpointKeyPerMode: { 0: "activeSetpoint", 1: "activeSetpoint", 2: "activeSetpoint", 3: "activeSetpoint", 5: "activeSetpoint" } };
const keysBySubType: any = {
  0: defaultKeys,
  1: { modeKey: "heaterMode", powerKey: "heaterTankONOFF", ambientTempKey: "heaterRoomTemp", setpointKeyPerMode: { 0: "heaterCoolingSP", 1: "heaterHeatingSP", 2: "", 3: "", 5: "" } },
  2: defaultKeys
};

const DashboardUnit: React.FC<RouteComponentProps<any> & any> = (props) => {
  const history = useHistory();
  const location: any = useLocation();
  const classes = useStyles();
  const match = useRouteMatch<{ unitId: string, sensorId: string, siteId: string, powerId: string }>();
  let { unitId = "", sensorId = "", siteId = "", powerId = "" } = match.params;

  const getUnitFromStore = useStoreState((s) => s.units.getUnit);
  const getUnitByIdSDK = useStoreActions((actions) => actions.units.getUnitByIdSDK);
  const getUnitSystemBrand = useStoreState((state) => state.units.getUnitSystemBrand);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [outdoorTemp, setOutdoorTemp] = useState("--");
  const [reFectUnit, setReFetchUnit] = useState<boolean>(false);
  const [externalTemp, setExternalTemp] = useState<any>(null);
  const [unitAlerts, setUnitAlerts] = useState<any>([]);
  const [tableUnitAlerts, setTableUnitAlerts] = useState<any>([]);
  const [unit, setUnit] = useState<any>(null);
  const [brandName, setBrandName] = useState<any>(null);
  const [brandNum, setBrandNum] = useState<any>(null);
  const [allObj, setAllObj] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const initializeAlerts = useStoreActions((action) => action.alerts.initialize);
  const [selectedTime, setSelectedTime] = useState<any>({}); //time picker on graph
  const [notesAnch, setNotesAnch] = useState<any>(null);
  const [unitNotes, setUnitNotes] = useState<any>(null);
  const [unitFiles, setUnitFiles] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [outsideAirTemp, setOutsideAirTemp] = useState<any>(null);
  const [remoconRoomTemp, setRemoconRoomTemp] = useState<any>(null);
  const [openAlthermaSettings, setOpenAlthermaSettings] = useState<boolean>(false);
  const { hvacBrands } = useStoreState((state) => state.types);
  const [isAltherma, handleIsAltherma] = useState<boolean>(false);
  const [sensor, setSensor] = useState<any>(null);
  const [sensorGroup, setSensorGroup] = useState<any>(null);
  const [openTrap, setOpenTrap] = useState<string>("");
  const [openSmartRule, setOpenSmartRule] = useState<any>({});
  const { customer: customerId = "", device: deviceId = "", system: systemId = "" }: any = unit || {};
  const [controlUnitState, setControlUnit] = useState<any>({});

  const {trackEvent} = useChurnZero()

  const unitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const getUnitNotes = useStoreActions((actions) => actions.notes.getUnitNotes);
  const newNote = useStoreActions((actions) => actions.notes.newNote);
  const deleteNote = useStoreActions((actions) => actions.notes.deleteNote);
  const getUnitFiles = useStoreActions((actions) => actions.files.getUnitFiles);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const unitCustomer = allCustomers[customerId] || null;
  const allAlerts = useStoreState((state) => state.alerts.allAlerts);
  const alerts = useStoreState((state) => state.alerts.parsedAlerts);
  const unitSystem = useStoreState((s) => s.systems.getSystem(systemId));
  const currentUser = useStoreState((s) => s.users.me);
  const { sensorId: selectedSensorId, sensorGroupId: selectedSensorGroupId, siteId: selectedSiteId, unitId: selectedUnitId, powerId: selectedPowerId, systemId: selectedSysId } = useStoreState((s) => s.selections.selections);
  const unitSite = useStoreState((s) => s.sites.getSite(selectedSiteId));
  const allUsers = useStoreState((state) => state.users.users);
  const uploadFile = useStoreActions((action) => action.files.uploadFile);
  const updateFilesInfo = useStoreActions((action) => action.files.updateFilesInfo);
  const types = useStoreState((state) => state.types);
  const allPowerMeters = useStoreState((a) => a.powerMeters.allPowerMeters);
  const getSensor = useStoreState((s) => s.sensors.getSensor);
  const getSensorGroup = useStoreState((s) => s.sensors.getSensorGroup);
  const unitDevice = allDevices[sensor ? sensor?.device : deviceId];
  const sensorDevice = allDevices[sensor?.device];
  const unitSubTypesMirror = useStoreState((state) => state.unitSubTypesMirror);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);

  const storedRuleId = localStorage.getItem("ruleId");

  const debouncedFetchData = debounce(async (selectedUnit) => {
    try {
      const notes = await getUnitNotes(selectedUnit);
      setUnitNotes(notes);
      const files = await getUnitFiles(selectedUnit);
      setUnitFiles(files);
    } catch (error) {
      console.error("debouncefetchData error: ", error);
    }
  }, 1000)

  const controlUnit = unit?.controlUnit && getUnitFromStore(unit.controlUnit);

  useEffect(() => {
    if (!unit?.controlUnit) {
      return;
    }
    getUnitByIdSDK(unit?.controlUnit)
      .then((u: any) => {
        setControlUnit(u);
      })
  }, [unit])

  const getBrandName = (value: any) => {
    for (let index in hvacBrands) {
      const brand = hvacBrands[+index] || {};
      if (brand.value === value) {
        return brand;
      }
    }
  };
  const { temperatureScale } = currentUser;
  const { eventTypes, unitTypes } = types;

  useEffect(() => {
    if (_.isEmpty(selectedTime)) {
      return;
    }

    const timeLimit = sitesFlags[selectedSiteId || ""]?.numOfHourLimitUnitDiag || 0;
    if (timeLimit === 0) {
      return;
    }
    const { selectedStartTime, selectedEndTime } = selectedTime;
    const timeNow = new Date().getTime();
    const pastTimeAllowed = timeNow - (timeLimit * 60 * 60 * 1000);
    if (selectedStartTime < pastTimeAllowed || selectedEndTime < pastTimeAllowed) {
      setSelectedTime({ selectedStartTime: pastTimeAllowed, selectedEndTime: timeNow });
    }
  }, [selectedSiteId]);

  useEffect(() => {
    const type = selectedUnitId ? "unit" : selectedSensorId ? "sensor" : selectedSensorGroupId ? "sensorGroup" : selectedPowerId ? "power" : "";

    if (!type || (type !== "unit" && !selectedSiteId)) {
      onUnitSelect(null);
      return;
    }
    if (type === "unit") {
      onUnitSelect(selectedUnitId);
      return;
    }
    if (type === "sensor") {
      history.push(`/unit-stats/site/${selectedSiteId}/sensor/${selectedSensorId}`);
      return;
    }
    if (type === "sensorGroup") {
      history.push(`/unit-stats/site/${selectedSiteId}/sensorGroup/${selectedSensorGroupId}`);
      return;
    }
    history.push(`/unit-stats/site/${selectedSiteId}/power/${selectedPowerId}`);
  }, [selectedUnitId, selectedSensorId, selectedPowerId, selectedSensorGroupId]);

  useEffect(() => {
    if (selectedSensorId) {
      const sensor = selectedSensorId === "all" ? { id: selectedSensorId, name: t`All Sensors`, site: selectedSiteId } : getSensor(selectedSensorId);
      setSensor(sensor ? { itemType: "sensor", ...sensor } : null);
      return;
    }

    if (selectedSensorGroupId) {
      const sGroup = selectedSensorGroupId === "all" ? { id: selectedSensorGroupId, name: t`All Sensor Groups`, site: selectedSiteId } : getSensorGroup(selectedSensorGroupId);
      if (selectedSensorGroupId === "all") {
        setSensor(sGroup ? { itemType: "sensor", ...sGroup } : null);
        setSensorGroup(null);
      } else {
        setSensorGroup(sGroup ? { itemType: "sensorGroup", ...sGroup } : null);
        setSensor(null);
      }
      return;
    }

    if (selectedPowerId) {
      const powerMeter = selectedPowerId === "all" ? { id: selectedPowerId, name: t`All Power Meters`, site: selectedSiteId } : allPowerMeters[selectedPowerId];
      setSensor(powerMeter ? { ...powerMeter, itemType: "powerMeter" } : null);
      setSensorGroup(null)
      return;
    }

    setSensorGroup(null)
    setSensor(null);
  }, [selectedSensorId, selectedPowerId, selectedSensorGroupId]);

  useEffect(() => {
    if (!selectedUnitId) {
      setUnit(null);
      return;
    }
    getUnitByIdSDK(selectedUnitId)
      .then((u: any) => {
        setUnit(u);
      });
    const brand = getUnitSystemBrand(selectedUnitId) || '';
    const brandObj = getBrandName(brand);
    setBrandNum(brand);
    setBrandName(brandObj?.name);
    handleIsAltherma(brandObj?.value === 10);

    if (isLoading) {
      return;
    }
    debouncedFetchData(selectedUnitId);
    return debouncedFetchData.cancel;
  }, [selectedUnitId, isLoading]);

  useEffect(() => {
    if (unit?.controlUnit) {
      unitUpdateStatus({ status: unit.controlUnit });
      return;
    }
    unitUpdateStatus({ status: "" });
  }, [unit?.controlUnit]);

  useEffect(() => {
    if (!reFectUnit) {
      return;
    }
    setReFetchUnit(false);
    setUnit(allUnits[unitId]);
    const brand = getUnitSystemBrand(unitId) || "";
    const brandObj = getBrandName(brand);
    setBrandNum(brand);
    setBrandName(brandObj?.name);
    handleIsAltherma(brandObj?.value === 10);
  }, [reFectUnit]);

  useEffect(() => {

    if (isLoading || !selectedUnitId || !selectedTime.selectedStartTime || !selectedTime.selectedEndTime) {
      return;
    }
    //get graph all alerts based on time frame
    getAlertsByFilters({
      startTime: selectedTime.selectedStartTime,
      endTime: selectedTime.selectedEndTime
    })
      .then((alertsByTime: any) => {

        if (_.isEmpty(alertsByTime)) {
          return;
        }
        initializeAlerts({ events: alertsByTime, openAlertsOnly: false });
      });

  }, [selectedUnitId, selectedTime.selectedStartTime, selectedTime.selectedEndTime, isLoading]);

  useEffect(() => {

    if (!selectedUnitId || _.isEmpty(allAlerts)) {
      return;
    }
    const unitAlerts: any = [];
    const tableUnitAlerts: any = [];
    Object.values(alerts).filter((alert: any) => {
      const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
        || eventTypes.entitiesHealthLowOstat === alert.type
        || eventTypes.entitiesHealthLowPstat === alert.type
        || eventTypes.entitiesHealthLowLqstat === alert.type
        || eventTypes.entitiesHealthLowSstat === alert.type;

      if (isEmpty(alert?.unitIds) || !some(alert?.unitIds, id => includes(unit?.serviceUnits, id) || id === unit?.controlUnit || id === unit?.id)) {
        return false;
      }

      if (isEntitiesNoDateType) {
        return false;
      }

      if ((alert.timestamp < selectedTime.selectedStartTime || alert.timestamp > selectedTime.selectedEndTime) && alert.status !== "Open") {
        return false;
      }

      if ((alert.timestamp < selectedTime.selectedStartTime || alert.timestamp > selectedTime.selectedEndTime) && alert.status === "Open") {
        tableUnitAlerts.push(alert);
        return false;
      }

      unitAlerts.push(alert);
      tableUnitAlerts.push(alert);
    });
    setUnitAlerts(unitAlerts);
    setTableUnitAlerts(tableUnitAlerts);
  }, [allAlerts, selectedUnitId, selectedTime]);

  const onUnitSelect = (unitId: string | null) => {
    const { unitId: urlId } = match.params

    if (unitId) {
      history.push({
        pathname: `/unit-stats/${unit}`
      });
    }

    else if (urlId) {
      updateSelections({ type: 'unit', data: urlId })
    }
    else {
      history.push("/unit-stats/");
    }
  };

  const clearUnit = (unitId: string | null) => {
    history.push(`/unit-stats/`);
  };

  const addFileLocally = (file: any) => {
    setUnitFiles([...unitFiles, file]);
  };

  const isBsUnit = unit?.type === unitTypes["bsBox"];
  const notOutdoor = unit?.type !== unitTypes["outdoor"];
  const isWaterHeater = unit?.type === unitTypes["indoor"] && unit?.subType === +unitSubTypesMirror.waterHeater;

  useEffect(() => {

    if (!allSites || !allSystems || !allUnits || allObj.length) {
      return;
    }

    const sites = Object.values(allSites).map((site: any) => {
      return {
        name: site.name,
        label: site.name,
        type: "site",
        id: site.id
      };
    });

    const systems = Object.values(allSystems).map((system: any) => {
      const siteName = allSites[system.site]?.name;
      return {
        name: system.name,
        label: `${siteName} - ${system.name}`,
        type: "system",
        id: system.id
      };
    });

    const units = Object.values(allUnits).reduce((val: any, unit: any) => {

      if (!allSites[unit.site]) {
        return val;
      }
      const siteName = allSites[unit.site]?.name;
      const systemName = allSystems[unit.system]?.name || t`Unassigned`;
      val.push({
        name: unit.name,
        label: `${siteName} - ${systemName} - ${unit.name}`,
        type: "unit",
        id: unit.id
      });
      return val;
    }, []);

    setAllObj([...sites, ...systems, ...units]);

  }, [allUnits]);

  const searchComponent = (
    <Autocomplete
      id="combo-box-demo"
      options={allObj}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label || ""}
      groupBy={(option) => option.type}
      value={""}
      inputValue={searchTerm}
      classes={{
        clearIndicator: classes.searchIcon,
        popupIndicator: classes.searchIcon,
        popper: classes.autoCompletePoper,
        paper: classes.autoCompletePaper,
        listbox: classes.autoCompleteItem,
        noOptions: classes.autoCompleteItem,
        groupLabel: classes.autoCompleteGroup,
        groupUl: classes.autoCompleteGroupUl

      }}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps, endAdornment: <Search /> }} />}
      onChange={(e: any, val: any) => {
        setSearchTerm("");
        if (!val) {
          return;
        }
        if (val.type === "unit") {
          onUnitSelect(val.id);
          return;
        }
        updateSelections({ type: val.type, data: val.id });
        onUnitSelect(null);
      }}
    />
  );

  const handelOnAlertClick = (alert: any) => {
    alert.ruleId ? openSmartRulePopup(alert.ruleId) : openAnomalyPopup(alert);
    trackEvent('ClickingOnAnAlert','')

  }

  const openSmartRulePopup = (ruleId: string) => {
    if (!ruleId) return;
    SDKSmartRule.getSmartRuleById(ruleId).then((rule: any) => {
      setOpenSmartRule(rule);
    }).catch((e: any) => { addMessage(e.message); });
  };

  const openAnomalyPopup = (alert: any) => {
    if (alert.trap && alert.type !== 201 && alert.type !== 202) {
      setOpenTrap(alert.trap);
    }
  };

  const deviceRegDate = (sensor ? sensorDevice?.registrationDate : unitDevice?.registrationDate) || new Date("1-1-1900");
  const { ambientTempKey } = keysBySubType[controlUnit ? controlUnit.subType : unit?.subType] || defaultKeys;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.rightSide}>
        <Header
          customGeneralNames={{ unit: t`Select Unit` }}
          hideAllOptionsFromDropdown={["unit"]}
          searchComponent={searchComponent}
          showSensors
          onSiteSelect={clearUnit}
          onSystemSelect={clearUnit}
          applySiteTypeFiltering
          screenTitle="unitDiag"
          allowUnassignedControls
        />

        {unit &&
          unitAlerts &&
          !_.isNil(unitCustomer) &&
          !_.isNil(unitSite) &&
          !_.isNil(unitDevice) ? (
          <Grid container direction={"row"} className={classes.contentContainer}>
            {/* --- start of unitInfoContainer  */}
            <Grid container direction={"row"} className={classes.unitInfoContainer}>
              <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                <UnitInfo
                  unit={unit}
                  site={unitSite}
                  device={unitDevice}
                  system={unitSystem}
                  setNotesAnch={setNotesAnch}
                  selectedSysId={selectedSysId}
                  unitNotes={unitNotes}
                  brandNum={brandNum}
                  setOpenAlthermaSettings={setOpenAlthermaSettings}
                />
              </Grid>
              <Grid item xs={2} className={classes.halfPadding}>
                {unit?.type !== unitTypes["outdoor"] && !isBsUnit ? (
                  <TemperatureInfo
                    unit={unit} site={unitSite}
                    externalTemp={externalTemp}
                    temperatureScale={temperatureScale}
                    roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnitState ? controlUnitState[ambientTempKey] : unit[ambientTempKey]}
                    brandName={brandName}
                    outsideAirTemp={outsideAirTemp}
                    controlUnit={controlUnitState}
                  />
                ) : (
                  <TemperatureInfo
                    unit={unit}
                    site={unitSite}
                    externalTemp={externalTemp}
                    externalOnly
                    temperatureScale={temperatureScale}
                    outdoorTemp={outdoorTemp}
                    roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnitState ? controlUnitState[ambientTempKey] : unit[ambientTempKey]}
                    brandName={brandName}
                    outsideAirTemp={outsideAirTemp}
                    controlUnit={controlUnitState}
                  />
                )}
              </Grid>
              <Grid item xs={3} className={classes.halfPadding}>
                {!isBsUnit && (controlUnit || unit?.type === 1) ? (
                  <UnitControl unit={unit?.type === 1 ? unit.id : controlUnit.id} temperatureScale={temperatureScale} device={unitDevice} isWaterHeater={isWaterHeater} />
                ) : null}
              </Grid>
            </Grid>
            {/* --- end of unitInfoContainer */}
            {/* --- start of paramsContainer  */}
            <Grid container direction={"row"} className={classes.halfPadding}>
              <Grid item xs={12} className={classes.paramsContainer}>
                <Card className={classes.statsRapper}>
                  {unit?.type === unitTypes["outdoor"] ? (
                    <UnitStats
                      site={unitSite}
                      unit={unit}
                      alerts={unitAlerts}
                      setOutdoorTemp={setOutdoorTemp}
                      setSelectedTime={setSelectedTime}
                      setReFetchUnit={setReFetchUnit}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedTime={selectedTime}
                      setExternalTemp={setExternalTemp}
                      setOutsideAirTemp={setOutsideAirTemp}
                      setRemoconRoomTemp={setRemoconRoomTemp}
                      minDate={deviceRegDate}
                      storedRuleId={storedRuleId}
                    />
                  ) : (
                    <UnitStats
                      site={unitSite}
                      unit={unit}
                      alerts={unitAlerts}
                      setOutdoorTemp={setOutdoorTemp}
                      setSelectedTime={setSelectedTime}
                      setReFetchUnit={setReFetchUnit}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedTime={selectedTime}
                      setExternalTemp={setExternalTemp}
                      setOutsideAirTemp={setOutsideAirTemp}
                      setRemoconRoomTemp={setRemoconRoomTemp}
                      minDate={deviceRegDate}
                      storedRuleId={storedRuleId}
                    />
                  )}
                </Card>
              </Grid>
              {/* --- end of paramsContainer */}
              {/* --- start of errorLogs and systemOverview */}
            </Grid>

            <Grid container direction={"row"} spacing={3} className={classes.bottomContainer}>
              <Grid item xs={5}>
                <Card
                  classes={{
                    root: classes.errorLogsCard
                  }}
                >
                  <ErrorLogs
                    unit={unit}
                    usePadding={true}
                    showTitle
                    title={t`Alerts`}
                    hideColumns={["unitName", "errorCode", "customerName", "system", "siteName", "alertType1"]}
                    unitAlerts={tableUnitAlerts}
                    hideEntity={false}
                    onAlertClick={handelOnAlertClick}
                    checkForTrapAlerts
                    showAck
                  />
                </Card>
              </Grid>

              <Grid item xs={7}>
                {unitSystem && (
                  <Card className={classes.systemOverviewCard}>
                    <SystemOverview system={unitSystem} assoBSBox={unit.branchBoxUnit} />
                  </Card>
                )}
              </Grid>

              {/* --- end of errorLogs and systemOverview */}
            </Grid>
          </Grid>
        ) : sensor ? (
          <Grid container direction={"row"} className={classes.sensorContainer}>
            <Grid container direction={"row"} className={classes.unitInfoContainer}>
              <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                <SensorInfo
                  sensorName={sensor?.name}
                  siteName={unitSite?.name || ""}
                  isDeviceConnected={sensor.id === "all" || powerId === "all" ? true : !!sensorDevice?.isConnected}
                  type={sensor.itemType}
                />
              </Grid>
              <Grid item xs={2} className={classes.halfPadding}>
                {(sensor.id !== "all" && sensor.itemType !== "powerMeter") && <SensorControl
                  id={sensor.id}
                  disabled={!sensorDevice?.isConnected}
                />}
              </Grid>
            </Grid>
            <Grid container direction={"row"} className={classes.halfPadding}>
              <Grid item xs={12} className={classes.paramsContainer}>
                <Card className={classes.statsRapper}>
                  <SensorStats
                    site={unitSite}
                    sensor={sensor}
                    setSelectedTime={setSelectedTime}
                    type={sensor.itemType}
                    allPowerMeters={allPowerMeters}
                    minDate={deviceRegDate}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        ) : sensorGroup ? <div style={{ overflow: "auto" }}>
          {sensorGroup.sensors.map((s: any) => {
            const tempSens: any = getSensor(s);
            if (!tempSens) return;
            const tempSensor: any = { itemType: "sensor", ...tempSens }
            const tempSensorDevice = allDevices[tempSensor?.device];
            return (
              <Grid container direction={"row"} className={classes.sensorContainer} key={tempSensor.id}>
                <Grid container direction={"row"} className={classes.unitInfoContainer}>
                  <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                    <SensorInfo
                      sensorName={tempSensor?.name}
                      siteName={unitSite?.name || ""}
                      isDeviceConnected={tempSensor.id === "all" || powerId === "all" ? true : !!tempSensorDevice?.isConnected}
                      type={tempSensor.itemType}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.halfPadding}>
                    {(tempSensor.id !== "all" && tempSensor.itemType !== "powerMeter") && <SensorControl
                      id={tempSensor.id}
                      disabled={!tempSensorDevice?.isConnected}
                    />}
                  </Grid>
                </Grid>
                <Grid container direction={"row"} className={classes.halfPadding}>
                  <Grid item xs={12} className={classes.paramsContainer}>
                    <Card className={classes.statsRapper}>
                      <SensorStats
                        site={unitSite}
                        sensor={tempSensor}
                        setSelectedTime={setSelectedTime}
                        type={tempSensor.itemType}
                        allPowerMeters={allPowerMeters}
                        minDate={tempSensorDevice?.registrationDate || new Date("1-1-1900")}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </div> : (
          <FilterRequire type={t`unit`} />
        )}
      </div>
      {notesAnch && selectedUnitId && selectedSysId && (
        <NotesAndAttachments
          anchorEl={notesAnch}
          setAnchorEl={setNotesAnch}
          unitId={unitId}
          systemId={systemId}
          unitNotes={unitNotes}
          setUnitNotes={setUnitNotes}
          createNewNote={newNote}
          deleteNote={deleteNote}
          allUsers={{ [currentUser.id]: currentUser, ...allUsers }}
          unitFiles={unitFiles}
          setUnitFiles={setUnitFiles}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadFile={uploadFile}
          updateFilesInfo={updateFilesInfo}
          addFileLocally={addFileLocally}
          siteId={unitSite?.id}
          timezone={unitSite?.timezone}
          canAddNoteUnit={unit?.permissions?.canAddNote !== false}
          canAddNoteSystem={unitSystem?.permissions?.canAddNote !== false}
          canDeleteNoteUnit={unit?.permissions?.canDelete !== false}
          canDeleteNoteSystem={unitSystem?.permissions?.canDelete !== false}
        />
      )}

      {selectedFile && (
        <Dialog
          open={selectedFile}
          keepMounted
          onClose={() => setSelectedFile(null)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paper: classes.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title">{`${selectedFile.name} preview`}</DialogTitle>
          <DialogContent>
            {selectedFile.isPhoto ?
              <img src={selectedFile.url} className={classes.imagePreview} /> :
              (<iframe
                src={`${selectedFile.isPdf ? "https://docs.google.com/gview?url=" : "https://view.officeapps.live.com/op/embed.aspx?src="}${selectedFile.url}${selectedFile.isPdf ? "&embedded=true" : ""}`}
                width="100%"
                height="100%"
                frameBorder="0"
                className={classes.frameStyle}
              >
              </iframe>)}
          </DialogContent>
          <DialogActions>
            <Button onMouseUp={() => downloadAs(selectedFile.url, selectedFile.name)} color="primary">
              {t`Download`}
            </Button>
            <Button onMouseUp={() => setSelectedFile(null)} color="primary">
              {t`Close`}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openAlthermaSettings &&
        <AlthermaUnitSettings
          unitId={selectedUnitId}
          device={unitDevice}
          system={unitSystem}
          onClose={() => setOpenAlthermaSettings(false)}
        />}
      {openTrap &&
        <AddRule
          close={() => setOpenTrap("")}
          trapId={openTrap}
          history={history}
          brand={brandNum}
          siteId={unitSite?.id}
        />}


      {!_.isEmpty(openSmartRule) &&
        <AddEditRule
          viewOnly
          rule={openSmartRule}
          onClose={() => setOpenSmartRule({})}
        />}

    </div>
  );
};

export default DashboardUnit;
