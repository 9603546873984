import React, { useState } from "react";
import {
  Dialog,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import styles from "./BacnetDeviceForm.style";
import { IBacnetDevice } from "@models/BacnetDevices";
import ErrorBox from "@components/WarningBox/ErrorBox";

interface IProps {
  entity: Partial<IBacnetDevice>;
  createEntity?: (props: any) => Promise<any>;
  updateEntity?: (props: any, id: string) => Promise<any>;
  deleteEntity?: (id: string) => Promise<any>;
  /** A flag indicating if editing is allowed. */
  canEdit?: boolean;
  onClose: () => void;
}

const BacnetDeviceForm = (props: IProps) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { entity, createEntity, updateEntity, deleteEntity, canEdit = true, onClose } = props;

  const [cancelClicked, setCancelClicked] = useState<boolean>(false);

  const onSubmit = (values: Partial<IBacnetDevice>) => {
    const entityBody: Partial<IBacnetDevice> = {
      title: values.title
    };

    if (entity.id && updateEntity) {
      updateEntity(entityBody,entity.id).finally(() => onClose());
    }
    if (!entity.id && createEntity) {
      createEntity(entityBody).finally(() => onClose());
    }
    
  };

  const formik = useFormik({
    initialValues: {name: entity.name, network: entity.network, title: entity.title},
    onSubmit,
  });

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{entity.id ? t`Edit bacnet device` : t`Add new bacnet device`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>

      <div id="dialogContent" className={classes.dialogContent}>
        <div style={{ position: "relative" }}>
                  <div className={classes.boxContent}>
                  <form onSubmit={formik.handleSubmit}>
                  <div className={classes.boxContent}>
                  <TextField
                      type="text"
                      name="name"
                      variant="outlined"
                      label="Name"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={formik.values.name}
                  />
                  </div>
                  <div className={classes.boxContent}>
                  <TextField
                      type="text"
                      name="network"
                      variant="outlined"
                      label="Network"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={formik.values.network}
                  />
                  </div>
                  <div className={classes.boxContent}>
                  <TextField
                      type="text"
                      name="title"
                      variant="outlined"
                      label="Title"
                      fullWidth
                      value={formik.values.title}
                      onChange={formik.handleChange}
                  />
                  </div>
                  <div className={classes.actionsContainer}>
                    <Button disabled={!canEdit} onClick={() => setCancelClicked(true)} onMouseDown={(event: any) => event.preventDefault()} marginRight white width={150}> {t`cancel`}</Button>
                    <Button disabled={!canEdit} type="submit"  width={150}> {t`save`}</Button>
                  </div>
                  </form>
                  </div>
        </div>
      </div>
      {cancelClicked && <ErrorBox error={"Do you want to discard changes?"} onAccept={onClose} onClose={() => setCancelClicked(false)} />}

    </Dialog>
  );
};

export default BacnetDeviceForm
