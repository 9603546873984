import { Action, action, actionOn, ActionOn, computed, Computed, debug, memo, Thunk, thunk } from "easy-peasy";
import _, { split } from "lodash";
import { t } from "ttag";
import urlFlagMap from "../constants/urlToFlagMapping";
import { ICustomer } from "./Customers";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";

export interface ISelections {
  customerId: string | null;
  siteId: string | null;
  systemId: string | null;
  unitId: string | null;
  lastSelectedUnitId: string | null;
  dateRange: IDateRange | null; // null - no date range selected
  isFromMap: boolean;
  deviceId: string | null;
  sensorId: string | null;
  sensorGroupId: string | null;
  powerId: string | null;
  bacnetDeviceId: string | null;
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

interface ISelection {
  type: "customer" | "site" | "system" | "unit" | "device" | "time" | "alertClickType" | "sensor" | "sensorGroup" | "powerMeter" | "bacnetDevice";
  data: any; //string | IDateRange | null;
  applySiteTypeFiltering?: boolean;
}

export interface ISelectionsModel {
  selections: ISelections;
  updateSelections: Thunk<ISelectionsModel, ISelection, any>;
  setSelections: Action<ISelectionsModel, Partial<ISelections>>;
  getCurrentSelection: Computed<ISelectionsModel>;
  getCustomersBySelection: Computed<ISelectionsModel, ICustomer[], IRootStoreModel>;
  getSitesBySelection: Computed<ISelectionsModel, ISite[], IRootStoreModel>;
  getSitesIdsBySelection: Computed<ISelectionsModel, string[], IRootStoreModel>;

  getSystemsBySelection: Computed<ISelectionsModel, any[], IRootStoreModel>;
  getUnitsBySelection: Computed<
    ISelectionsModel,
    (type: "indoor" | "outdoor" | "control" | "bsBox" | "other" | "sensor" | "sensorGroup" | "powerMeters" | "bacnetDevices", screenTitle?: string, ignoreSystem?: boolean, applySiteTypeFiltering?: boolean, allowUnassignedControls?: boolean) => any,
    IRootStoreModel
  >;
  getControlUnitsBySite: Computed<ISelectionsModel, (siteId: string, storedUnits: any, units?: any) => any, IRootStoreModel>;
  getExternalsBySelection: Computed<ISelectionsModel, boolean, IRootStoreModel>;
  updateExternalSelections: Thunk<ISelectionsModel, { siteId: any, type: any }, any>;
  mobileSelections: any;
  updateMobileSelections: Thunk<ISelectionsModel, {
    customerId?: any;
    siteId?: any;
    unitId?: any;
  }, any>;
  setMobileSelections: Action<ISelectionsModel, any>;
  getIndoorUnitsBySite: Computed<ISelectionsModel, (siteId: string, storedUnits: any) => any, IRootStoreModel>;
  getSitePowerMeters: Computed<ISelectionsModel, (siteId: string, storedUnits: any, powerMeters?: any) => any, IRootStoreModel>;
  updateMobileSelectionsAll: Thunk<ISelectionsModel, {
    customerId?: any;
    siteId?: any;
    unitId?: any;
  }, boolean>;

}

export const selectionsModel: ISelectionsModel = {
  selections: {
    customerId: null,
    siteId: null,
    systemId: null,
    unitId: null,
    lastSelectedUnitId: null,
    dateRange: null,
    isFromMap: false,
    deviceId: null,
    sensorId: null,
    sensorGroupId: null,
    powerId: null,
    bacnetDeviceId: null
  },
  updateSelections: thunk(async (actions, payload, { getStoreState, getStoreActions, injections }) => {
    const { sdkUser } = injections;

    if (payload.type === "time") {
      actions.setSelections({ dateRange: payload.data as IDateRange });
      return;
    }

    let newSelection: any = {
      customerId: null,
      siteId: null,
      systemId: null,
      unitId: null,
      deviceId: null,
      sensorId: null,
      sensorGroupId: null,
      powerId: null,
      bacnetDeviceId: null
    };

    const rootState: any = getStoreState();
    const oldSelections = rootState.selections.selections;

    switch (payload.type) {

      case "sensor":
        if (payload.data === "all") {
          newSelection = { ...oldSelections, powerId: null, sensorGroupId: null, sensorId: payload.data };
          break;
        }
        const sensor = rootState.sensors.allSensors[payload.data];
        const { sensorTypes } = rootState.types;
        if (!_.isEmpty(sensor) && sensorTypes[sensor.type]?.enableView) {
          newSelection.customerId = sensor.customer ? sensor.customer : oldSelections.customerId;
          newSelection.siteId = sensor.site ? sensor.site : oldSelections.siteId;
          newSelection.systemId = "externals";
          newSelection.sensorId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, sensorId: null, powerId: null, sensorGroupId: null };
        }
        break;

      case "sensorGroup":
        if (payload.data === "all") {
          newSelection = { ...oldSelections, powerId: null, sensorId: null, sensorGroupId: payload.data };
          break;
        }
        const sensorGroup = rootState.sensors.allSensorGroups[payload.data];
        if (!_.isEmpty(sensorGroup)) {
          newSelection.customerId = sensorGroup.customer ? sensorGroup.customer : oldSelections.customerId;
          newSelection.siteId = sensorGroup.site ? sensorGroup.site : oldSelections.siteId;
          newSelection.systemId = "externals";
          newSelection.sensorGroupId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, sensorId: null, powerId: null, sensorGroupId: null };
        }
        break;

      case "powerMeter":
        if (payload.data === "all") {
          newSelection = { ...oldSelections, sensorId: null, sensorGroupId: null, powerId: payload.data };
          break;
        }
        const powerMeter = rootState.powerMeters.allPowerMeters[payload.data];
        if (!_.isEmpty(powerMeter)) {
          const customerId = rootState.sites.allSites[powerMeter.site]?.customer;

          newSelection.customerId = customerId ? customerId : oldSelections.customerId;
          newSelection.siteId = powerMeter.site ? powerMeter.site : oldSelections.siteId;
          newSelection.systemId = "externals";
          newSelection.powerId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, sensorId: null, powerId: null, sensorGroupId: null };
        }
        break;

      case "bacnetDevice":
        if (payload.data === "all") {
          newSelection = { ...oldSelections, sensorId: null, sensorGroupId: null, powerId: null, bacnetDeviceId: payload.data };
          break;
        }
        const bacnetDevice = rootState.bacnetDevices.allBacnetDevices[payload.data];
        if (!_.isEmpty(bacnetDevice)) {
          const customerId = rootState.sites.allSites[bacnetDevice.site]?.customer;

          newSelection.customerId = customerId ? customerId : oldSelections.customerId;
          newSelection.siteId = bacnetDevice.site ? bacnetDevice.site : oldSelections.siteId;
          newSelection.systemId = null;
          newSelection.powerId = null;
          newSelection.bacnetDeviceId = bacnetDevice.id;
        }
        else {
          newSelection = { ...oldSelections, sensorId: null, powerId: null, sensorGroupId: null, bacnetDeviceId: null };
        }
        break;

      case "unit":
        let unit = rootState.units.allUnits[payload.data];

        if (!_.isEmpty(unit)) {
          newSelection.customerId = unit.customer ? unit.customer : oldSelections.customerId;
          newSelection.siteId = unit.site ? unit.site : oldSelections.siteId;
          newSelection.systemId = unit.system ? unit.system : oldSelections.systemId;
          newSelection.unitId = payload.data;
        }
        else {
          newSelection = { ...oldSelections, unitId: null };
        }
        break;
      case "system":
        if (!payload.data) {
          newSelection = { ...oldSelections, unitId: null, systemId: null, powerId: null, sensorId: null, sensorGroupId: null };
          break;
        }
        if (payload.data === "externals") {
          newSelection = { ...oldSelections, unitId: null, systemId: payload.data };
          break;
        }

        const sys = rootState.systems.allSystems[payload.data];
        if (!_.isEmpty(sys)) {
          newSelection.customerId = sys.customer ? sys.customer : oldSelections.customerId;
          newSelection.siteId = sys.site ? sys.site : oldSelections.siteId;
          newSelection.systemId = payload.data;
        }
        else {
          const unassignedUnits: any = Object.values(rootState.units.allUnits).filter(
            (unit: any) => _.isNil(unit.system)
          );
          let systems: any = {};
          for (let unit of unassignedUnits) {
            if (!systems[unit.line + "_" + unit.device]) {
              systems[unit.line + "_" + unit.device] = [unit];
            }
            else {
              systems[unit.line + "_" + unit.device].push(unit);
            }
          }
          if (systems[payload.data]) {
            newSelection.siteId = systems[payload.data][0].site ? systems[payload.data][0].site : oldSelections.siteId;
            const site = rootState.sites.allSites[newSelection.siteId];
            newSelection.customerId = site ? site.customer : oldSelections.customerId;
            newSelection.systemId = payload.data;
          }
          else {
            newSelection = { ...oldSelections, unitId: null, systemId: null };

          }
        }
        break;
      case "site":
        const site = rootState.sites.allSites[payload.data];
        if (!_.isEmpty(site)) {
          newSelection.customerId = site.customer ? site.customer : oldSelections.siteId;
          newSelection.siteId = payload.data;
        }
        else {
          if (payload.data === "groups" && window.location.href.indexOf("/control") > -1) {
            newSelection = { ...oldSelections, unitId: null, systemId: null, siteId: "groups" };
            break;
          }
          newSelection = { ...oldSelections, unitId: null, systemId: null, siteId: null, sensorId: null, powerId: null, sensorGroupId: null, };
        }
        (getStoreActions() as any).ReportsData.resetAllData();
        break;
      case "customer":
        newSelection.customerId = payload.data;
        break;
      default:
        break;

    }

    const isCustomerChanged = newSelection.customerId !== oldSelections.customerId;
    if (isCustomerChanged) {
      const customerObj = payload.data && rootState.customers.allCustomers[payload.data];
      (window as any).selectedCustomer = customerObj?.name || "";

      const shouldNotUseWebsocket = customerObj && customerObj?.useWebsocket === false;
      if (!shouldNotUseWebsocket) {
        if (!sdkUser.ws) {
          (getStoreActions() as any).openWebsocket(false);
        }
      }
      else {
        sdkUser.closeWebSocket();
      }
    }

    actions.setSelections(newSelection);
  }),

  setSelections: action((state, payload) => {
    state.selections = { ...state.selections, ...payload };
  }),

  getCurrentSelection: computed([(state) => state.selections], (selections) => {
    if (selections.unitId) {
      return { type: "unitId", id: selections.unitId };
    }
    if (selections.systemId) {
      return { type: "systemId", id: selections.systemId };
    }
    if (selections.siteId) {
      return { type: "siteId", id: selections.siteId };
    }
    if (selections.customerId) {
      return { type: "customerId", id: selections.customerId };
    } else {
      return { type: "customerId", id: "all" };
    }
  }),

  getCustomersBySelection: computed(
    [(state) => state.selections, (state, storeState) => storeState.customers.allCustomers],
    (selections, allCustomers) => {
      return _.orderBy(
        Object.values(allCustomers),
        [(customer) => customer.name?.toUpperCase()],
        ["asc"]
      );
    }),

  getSitesBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (selections, allSites, allCustomers) => {
      return _.orderBy(
        Object.values(allSites).filter((site) =>
          selections.customerId
            ? selections.customerId === site.customer
            : true
        ),
        [(site) => site.name?.toUpperCase()],
        ["asc"]
      );
    }),
  getSitesIdsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (selections, allSites, allCustomers) => {

      const sitesIds: any = [];
      Object.values(allSites).forEach((site: any) => selections.customerId === site.customer && sitesIds.push(site.id));

      return _.orderBy(sitesIds,
        [(siteId) => allSites[siteId]?.name?.toUpperCase()],
        ["asc"]
      );

    }),
  getSystemsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.customers.allCustomers,
      (state, storeState) => storeState.units.allUnits
    ],
    (selections, allSites, allSystems, allDevices, allCustomers, allUnits) => {

      return _.orderBy(
        Object.values(allSystems).filter((system) => {
          const systemDevice = allDevices[system.device];
          if (_.isUndefined(systemDevice)) { return false; }
          const systemSite = allSites[systemDevice.site];
          if (_.isUndefined(systemSite)) { return false; }
          const systemCustomerId = systemSite.customer;
          return (
            (selections.customerId ? systemCustomerId === selections.customerId : true) &&
            (selections.siteId ? systemSite.id === selections.siteId : true)
          );

        }),
        [(system) => system.name?.toUpperCase()],
        ["asc"]
      );
    }),

  getUnitsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.powerMeters.allPowerMeters,
      (state, storeState) => storeState.sensors.allSensors,
      (state, storeState) => storeState.sensors.allSensorGroups,
      (state, storeState) => storeState.bacnetDevices.allBacnetDevices,
      (state, storeState) => storeState.types,
      (state, storeState) => storeState.sites.sitesFlags

    ],
    (selections, allUnits, allDevices, allSites, allPowerMeters, allSensors, allSensorGroups, allBacnetDevices, types, sitesFlags) => (type, screenTitle = "", ignoreSystem = false, applySiteTypeFiltering = false, allowUnassignedControls = false) => {
      if (type === "sensor") {
        const { sensorTypes } = types;
        //sensors that are in a sensor group will not appear in the sensors section
        const options = Object.values(allSensors).filter((sensor: any) => sensorTypes[sensor.type]?.enableView && !sensor.sensorGroup ? selections.siteId === sensor.site : false);

        if (options.length > 1) {
          const sortedOptions = _.sortBy(options, "name");
          return [{ id: "all", name: t`All Sensors` }, ...sortedOptions];
        }
        return options;
      }

      if (type === "sensorGroup") {
        const options = Object.values(allSensorGroups).filter((sensorGroup: any) => selections.siteId === sensorGroup.site);
        if (options.length > 1) {
          const sortedOptions = _.sortBy(options, "name");
          return sortedOptions;
          // Hide "all sensor groups option"
          // return [{ id: "all", name: t`All Sensor Groups` }, ...sortedOptions];

        }
        return options;
      }

      if (type === "powerMeters") {
        const { powerMeterTypes } = types;
        const options = Object.values(allPowerMeters).filter((powerMeter: any) => selections.siteId === powerMeter.site && powerMeter.type === powerMeterTypes.normal);

        if (options.length > 1) {
          const sortedOptions = _.sortBy(options, "name");
          return [{ id: "all", name: t`All Power Meters` }, ...sortedOptions];
        }
        return options;
      }

      if (type === "bacnetDevices") {
        const options = Object.values(allBacnetDevices).filter((bacnetDevice: any) => selections.siteId === bacnetDevice.site);

        if (options.length > 1) {
          const sortedOptions = _.sortBy(options, "name");
          return [{ id: "all", name: t`All BACnet Devices` }, ...sortedOptions];
        }
        return options;
      }

      const { unitTypes } = types;
      const filterType = unitTypes[type === "control" ? "indoor" : type === "indoor" ? "service" : type];

      return Object.values(allUnits).filter((unit: any) => {
        const { type: unitType, customer, site, system, serviceUnits = [], isVisible } = unit;
        const { isServiceSite } = sitesFlags[site] || {};

        if (unitType !== filterType) {
          return false;
        }

        if (!isVisible) {
          return false;
        }

        if (type === "control" && (applySiteTypeFiltering && isServiceSite && !allowUnassignedControls)) {
          // tslint:disable-next-line: no-console
          site === selections.siteId && system === selections.systemId && console.log(0);
          return false;
        }
        if (type === "control" && (applySiteTypeFiltering && isServiceSite && allowUnassignedControls)) {
          if (serviceUnits.length !== 0) {
            return false;
          }
        }

        if ((type === "indoor" || type === "other") && (applySiteTypeFiltering && !isServiceSite)) {
          return false;
        }

        if ((type === "bsBox" || type === "outdoor") && applySiteTypeFiltering && !isServiceSite) {
          return false;
        }

        const flag = urlFlagMap[screenTitle];
        return (
          (flag ? sitesFlags[site] && sitesFlags[site][flag] : true) &&
          (selections.customerId ? customer === selections.customerId : true) &&
          (selections.siteId ? site === selections.siteId : true) &&
          (selections.systemId && !ignoreSystem ? (system === selections.systemId) : true)
        );
      });
    }
  ),
  getControlUnitsBySite: computed(
    [
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.types
    ],
    (storeUnits, allDevices, allSites, types) => (siteId: any, storedUnits: any = new Map(), units = "") => {
      const allUnits: any = units ? units : storeUnits;
      const { unitTypes = {} } = types;
      let siteUnits: any = new Map();

      Object.values(allUnits).forEach((unit: any, index: number) => {
        if (!unit.site || (unit.type !== unitTypes.indoor && unit.type !== unitTypes.sensor) || unit.site !== siteId || !unit.isVisible) {
          return;
        }

        siteUnits.set(unit.id, true);
        storedUnits.set(unit.id, true);

      });

      Array.from(storedUnits).forEach((unit: any) => {
        const fullUnit = allUnits[unit[0]];
        if (!fullUnit || !siteUnits.has(unit[0]) || !allUnits[unit[0]]?.isVisible) {
          storedUnits.delete(unit[0]);
        }
      });

      return Array.from(storedUnits);
    }
  ),
  getSitePowerMeters: computed(
    [
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.types,
      (state, storeState) => storeState.powerMeters.allPowerMeters
    ],
    (allSites, types, storePowerMeters) => (siteId: any, storedPowerMeters: any = new Map(), powerMeters = "") => {
      const allPowerMeters: any = powerMeters ? powerMeters : storePowerMeters;
      let sitePowerMeters: any = new Map();

      Object.values(allPowerMeters).forEach((powerMeter: any, index: number) => {
        if (!powerMeter.site || powerMeter.site !== siteId || powerMeter.type !== 0) {
          return;
        }

        sitePowerMeters.set(powerMeter.id, true);
        storedPowerMeters.set(powerMeter.id, true);

      });

      Array.from(storedPowerMeters).forEach((powerMeter: any) => {
        const fullPowerMeter = allPowerMeters[powerMeter[0]];
        if (!fullPowerMeter || !sitePowerMeters.has(powerMeter[0])) {
          storedPowerMeters.delete(powerMeter[0]);
        }
      });

      return Array.from(storedPowerMeters);
    }
  ),
  getExternalsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sensors.allSensors,
      (state, storeState) => storeState.powerMeters.allPowerMeters,
      (state, storeState) => storeState.types
    ],
    (selections, sensors, powerMeters, types) => {
      const { siteId } = selections;
      if (!siteId) {
        return false;
      }

      let hasExternals = false;
      for (let sensorId in sensors) {
        const sensor = sensors[sensorId];
        if (sensor.site === siteId) { //hide inactive
          hasExternals = true;
          break;
        }
      }

      if (hasExternals) {
        return true;
      }

      for (let powerId in powerMeters) {
        const powerMeter = powerMeters[powerId];
        const { powerMeterTypes } = types;
        if (powerMeter.site === siteId && powerMeter.type === powerMeterTypes.normal) {
          hasExternals = true;
          break;
        }
      }

      return hasExternals;
    }),
  updateExternalSelections: thunk((actions, payload, { getStoreState }) => {
    //this is only to be used when id is all and on first load
    const { type, siteId } = payload;
    const rootState: any = getStoreState();
    const site = rootState.sites.allSites[siteId];
    if (!site) {
      return;
    }
    const { customer } = site;

    if (type === "sensor") {
      const allSensors = rootState.sensors.allSensors;
      const { sensorTypes } = rootState.types;
      let count = 0;

      for (let id in allSensors) {
        const { type, site } = allSensors[id];
        const enableView = sensorTypes[type]?.enableView;

        if (!enableView || site !== siteId) {
          continue;
        }
        count = count + 1;

        if (count === 2) {
          break;
        }
      }
      if (count === 2) {
        actions.setSelections({ customerId: customer, siteId, sensorId: "all", systemId: "externals" });
      }
      return;
    }

    const allPowerMeters = rootState.powerMeters.allPowerMeters;
    let count = 0;

    for (let id in allPowerMeters) {
      const { site } = allPowerMeters[id];
      if (site !== siteId) {
        continue;
      }
      count = count + 1;

      if (count === 2) {
        break;
      }
    }
    if (count === 2) {
      actions.setSelections({ customerId: customer, siteId, powerId: "all", systemId: "externals" });
    }
  }),
  mobileSelections: {
    customerId: null,
    siteId: null,
    unitId: null
  },
  updateMobileSelections: thunk(async (actions, payload, { getStoreActions, getStoreState, injections }) => {
    const { sdkUser } = injections;
    const state: any = getStoreState();
    const isCustomerChanged = payload.customerId !== state.selections.mobileSelections.customerId;
    if (isCustomerChanged) {
      const customerObj = payload.customerId && state.customers.allCustomers[payload.customerId];
      const shouldNotUseWebsocket = customerObj && customerObj?.useWebsocket === false;
      if (!shouldNotUseWebsocket) {
        (getStoreActions() as any).openWebsocket(true);
      }
      else {
        sdkUser.closeWebSocket();
      }
    }
    actions.setMobileSelections(payload);
  }),
  updateMobileSelectionsAll: thunk((actions, payload, { getStoreState }) => {
    const state: any = getStoreState();

    if (payload.siteId) {
      const site = state.sites.allSites[payload?.siteId];

      if (site) {
        actions.setMobileSelections({ customerId: site.customer, siteId: site.id, unitId: "" });
        return true;
      }
      return false;
    }
  }),
  setMobileSelections: action((state, payload) => {
    state.mobileSelections = payload;
  }),
  getIndoorUnitsBySite: computed(
    [
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.types
    ],
    (allUnits, allDevices, allSites, types) => (siteId: any, storedUnits: any = new Map()) => {
      const { unitTypes = {} } = types;
      let siteUnits: any = new Map();

      Object.values(allUnits).forEach((unit: any, index: number) => {
        if (!unit.site || (unit.type !== unitTypes.indoor && unit.type !== unitTypes.waterHeater) || unit.site !== siteId) {
          return;
        }

        siteUnits.set(unit.id, true);
        storedUnits.set(unit.id, true);

      });

      Array.from(storedUnits).forEach((unit: any) => {
        if (!allUnits[unit[0]] || !siteUnits.has(unit[0])) {
          storedUnits.delete(unit[0]);
        }
      });

      return Array.from(storedUnits);
    }
  )
};
